import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams, Outlet } from "react-router-dom"
import routing from "../routing.json"
import ConnectionStatus from "./general/ConnectionStatus"
import { ReactComponent as QuickTrainerIcon } from "../resource/icon_quicktrainer.svg";
import { ReactComponent as JobAssistantIcon } from "../resource/icon_jobassistant.svg";
import { ReactComponent as NewJobIcon } from "../resource/icon_newjob.svg";

import { NavMenu } from "./navMenu"

const QuickRoute = (props) => {
    const connectionStatus = useSelector(state => state.connectionStatus)

    return (
        <Link 
        to={connectionStatus==="connected" ? props.route : false} 
        class={`quick-route ${connectionStatus==="connected" ? "active" : ""} ${props.prefix && props.prefix }`} >
            <p>
                {props.text}
            </p>
            {props.icon && props.icon}
            
        </Link>
    )
}

export default function HomePage(props) {
    const routes = routing
    let params = useParams()

    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const [shortModelList, setShortModelList] = useState([])

    const routeToQuickTrainer = `/${params.userName}/${routes.quickTrainer}`
    const routeToJobAssistant = `/${params.userName}/${routes.jobAssistant}`

    useEffect(() => {
        setShortModelList(availableClassificationModels.slice(0, 3))
    }, [availableClassificationModels])

    return (
        <section id="home-page" className={`scroll-body-wrapper ${props.hideText ? "visually-hidden" : ""}`}>
            <header id="home-page__header">
                <h1>
                    Home Page
                </h1>
                <div id="home-page__settings">
                    <ConnectionStatus />
                    <NavMenu />
                </div>
            </header>
            <nav id="home-page__body" className="scroll_body">
                <QuickRoute
                    text="QuickTrainer"
                    route={routeToQuickTrainer}
                    icon={<QuickTrainerIcon class="orange" />}
                    />
                <QuickRoute
                    text="JobAssistant"
                    route={routeToJobAssistant}
                    icon={<JobAssistantIcon class="orange" />} />
                <QuickRoute
                    text="Create New Job"
                    route={`${routeToJobAssistant}?${routes.searchParams.statusParam}=${routes.searchParams.createNewJob}&${routes.searchParams.jobIDParam}=new`}
                    icon={<NewJobIcon />} />

                <div className="quick-route altered">
                    <p>
                        Choose Classification Model
                    </p>
                    <div className="quick-route__model-access">
                        {shortModelList.map((model, index) => {
                            return (
                                <Link
                                    key={index} to={`${routeToQuickTrainer}?${routes.searchParams.classModelIDParam}=${model.name}`} >
                                    {model.name}
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </nav>
        </section>
    )
}
