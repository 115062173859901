//React Components import
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
//Redux/ DataManagement import
import {
    toggleJobListTransfer, requestWorkPlans, setJobList,
    setFetchedWorkPlans, toggleEditedJobTransfer, setEditedJob,
    initRequiredModelData, setDeletedJob, toggleDeletedJobTransfer
} from '../../redux/actions';
// Third party Components import

// Own Components import
import VideoFeed from '../videoFeed';
import JobList from './JobList';
import GuideCardSlider from './GuideCardSlider';
import EditJobMode from './EditJobMode';
import { extractModelName, addPathsToWorkplan } from "../../helpers/utilities";
import { Badge } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
// Helpers import
import routing from "../../routing.json"
const JobAssistant = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const routes = routing
    let params = useParams()
    let location = useLocation()

    var userName = props.userName ? props.userName : params.userName;

    // For developementd

    // Redux States
    const newJobListFromServer = useSelector(state => state.jobList)
    const newWorkplanDataFromServer = useSelector(state => state.fetchedWorkPlans)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const requiredModelData = useSelector(state => state.requiredModelData)

    // Local Storage
    const [updateJobListFromServer, setUpdateJobListFromServer] = useState(false)
    const [workplanToEdit, setWorkplanToEdit] = useState({})
    const [workplanToExecute, setWorkplanToExecute] = useState({})

    const jobList = useRef([])
    const workplanData = useRef([])
    const counter = useRef(0);
    const localStream = useRef()
    const receivedWorkPlans = useRef(false)

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setUpdateJobListFromServer(true)
    }, [])

    useEffect(() => {
        let vars = props.videoStream
    }, [props])

    useEffect(() => {
        console.log("JobassistantApp rendered", counter.current++)
        if (updateJobListFromServer) {
            console.log("toggleJobListTransfer")
            dispatch(toggleJobListTransfer())
            setUpdateJobListFromServer(false)
        }
        if (newJobListFromServer && newJobListFromServer.length) {
            console.log("Got a new JobList from BackendServer", newJobListFromServer)
            jobList.current = newJobListFromServer
            dispatch(setJobList([]))
            const jobFiles = jobList.current.map((job) => { return job.fileName })
            dispatch(requestWorkPlans(jobFiles)) // Request WorkPlan based on fileName
        }
        if (newWorkplanDataFromServer && newWorkplanDataFromServer.length) {
            console.log("Got some workplans", newWorkplanDataFromServer)
            workplanData.current = extractModelName(newWorkplanDataFromServer)
            checkJobStatus()
            dispatch(setFetchedWorkPlans([]))
            receivedWorkPlans.current = true
            executeOnSearchParams()
        }
        // props.setHideVideoFeed(Object.keys(workplanToExecute).length ? false : true)
    })

    useEffect(() => {
        if (workplanData.current && workplanData.current.length) checkJobStatus()
    }, [availableClassificationModels])

    function executeOnSearchParams() {
        let status = searchParams.get(routes.searchParams.statusParam)
        let jobID = searchParams.get(routes.searchParams.jobIDParam)
        let workplan = workplanData.current.find((value) => { return value.id === jobID })
        switch (status) {
            case routes.searchParams.executeJob:
                workplan && setWorkplanToExecute(workplan)
                break
            case routes.searchParams.editJob:
                workplan && setWorkplanToEdit(workplan)
                break
            case routes.searchParams.createNewJob:
                addNewJob(jobID, jobID)
                break
            default:
        }
    }

    const chooseWorkplan = (jobID) => {
        const selectedWorkplanData = workplanData.current.find((value) => { return value.id === jobID })
        console.log("Selected Workplan", selectedWorkplanData)
        setWorkplanToExecute(selectedWorkplanData)
    }

    const chooseEditWorkplan = (jobID) => {
        const selectedWorkplanData = workplanData.current.find((value) => { return value.id === jobID })
        console.log("Selected Workplan to Edit", selectedWorkplanData)
        console.log("Workplan to execute", workplanToExecute)
        setWorkplanToEdit(selectedWorkplanData)
    }

    const addNewJob = (newJobID, newFileName) => {
        let jobIDExists = workplanData.current.some(workplan => workplan.id === newJobID)
        if (jobIDExists) {
            let i = 1
            while (true)
                if (workplanData.current.find(workplan => workplan.id === `${newJobID}(${i})`)) {
                    i = i + 1
                }
                else {
                    newJobID = newJobID + `(${i})`
                    newFileName = newFileName + `(${i})`
                    break
                }
        }
        let newJobListEntry = {
            fileName: newFileName,
            isExecutable: true,
            jobID: newJobID
        }
        jobList.current.push(newJobListEntry)
        let newWorkPlanDataEntry = {
            "id": newJobID,
            "autorun": true,
            "priority": 10,
            "job-image": "",
            "tasks": []
        }
        workplanData.current.push(newWorkPlanDataEntry)
        let editedJob = {
            "fileName": newFileName,
            "jobData": newWorkPlanDataEntry,
            "status": "new"
        }
        dispatch(setEditedJob(editedJob))
        dispatch(toggleEditedJobTransfer())
        setWorkplanToEdit(newWorkPlanDataEntry)
    }

    const deleteJob = (deletedJob) => {
        jobList.current = jobList.current.filter(job => job.fileName !== deletedJob.fileName)
        workplanData.current = workplanData.current.filter(workplan => workplan.id !== deletedJob.jobID)
        dispatch(setDeletedJob(deletedJob))
        dispatch(toggleDeletedJobTransfer())
    }

    const updateWorkplanData = (jobID, workplan) => {
        if (workplan.some(tasks => tasks.id === "")) {
            console.log("Some tasks without id")
        } else {
            console.log("Now saved workplandata", jobID, workplan)
            const indexToChange = workplanData.current.findIndex(obj => obj.id === jobID);
            console.log("changing index", indexToChange)
            workplanData.current[indexToChange].tasks = workplan
            checkJobStatus()
            // Send edited Job to server with paths.
            let fileName = jobList.current.find(job => job.jobID === jobID).fileName
            let tempObj = { "fileName": fileName, "jobData": addPathsToWorkplan(workplanData.current)[indexToChange], "status": "edited" }
            dispatch(setEditedJob(tempObj))
            dispatch(toggleEditedJobTransfer())
        }
    }

    const checkJobStatus = () => {
        jobList.current.map(job => {
            let isExecutable = true
            let jobData = workplanData.current.find(workplan => workplan.id === job.jobID)
            try {
                if (jobData?.tasks) {
                    isExecutable = !jobData.tasks.some(task => {
                        // If task has no class-model return false 
                        if (task.hasOwnProperty("classification-model") && task["classification-model"]!=="") {
                            // Check if a task has a class model which is not included in availableClassModels 
                            // If it is not included, return true so that the some.function stops.
                            if (!availableClassificationModels.some(model => model.name === task["classification-model"])) return true
                            // Check if one of the labels of the task are not included in availableClassModels labelList 
                            let labelList = availableClassificationModels.find(model => model.name === task["classification-model"]).labels
                            if (task.parts.some(part => !labelList.includes(part.id))) return true
                        } else {
                            return false
                        }
                    })
                }
            }
            catch {
                job["isExecutable"] = false
                console.log("CHECKJOBSTATUS_ERROR")
            }
            job["isExecutable"] = isExecutable
        })
    }

    function setLocalStream(stream) {
        localStream.current = stream
    }

    return (
        <div className="job-assistant">
            {/* <VideoFeed userName={userName} setLocalStream={setLocalStream} hide={true} /> */}
            {/* {console.log(jobList.current)}
            {console.log(workplanData)} */}
            {(!Object.keys(workplanToExecute).length && !Object.keys(workplanToEdit).length) &&
                <JobList
                    jobListData={jobList.current}
                    selectWorkplan={chooseWorkplan}
                    editWorkplan={chooseEditWorkplan}
                    updateWorkplanData={updateWorkplanData}
                    addNewJob={addNewJob}
                    deleteJob={deleteJob}
                />}
            {Object.keys(workplanToExecute).length && !Object.keys(workplanToEdit).length &&
                <GuideCardSlider
                    workplanData={workplanToExecute}
                    videoStream={props.videoStream}
                    setWorkplanToExecute={setWorkplanToExecute}
                    setWorkplanToEdit={setWorkplanToEdit} />}

            {Object.keys(workplanToEdit).length && !Object.keys(workplanToExecute).length &&
                <EditJobMode
                    workplanData={workplanToEdit}
                    updateWorkplanData={updateWorkplanData}
                    setWorkplanToExecute={setWorkplanToExecute}
                    setWorkplanToEdit={setWorkplanToEdit} />}
            {/* {Object.keys(workplanToEdit).length && !Object.keys(workplanToExecute).length && <div style={{position:"fixed",zIndex:9,top:"0%",display:"flex",justifyContent:"center",alignItems:"center",font:"bold 20vh",width:"100%",height:"100%",color:"red",border:"solid 10px red"}}>
                <h1>EditMode has been deactivated</h1>
            </div>} */}
        </div>

    )

}

export default JobAssistant;