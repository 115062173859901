import { useEffect, useRef, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap";
import { capitalizeFirstLetter, replaceUmlaute } from "../helpers/utilities";

/**
 * Props Value:
 * keyToChange
 * valueToChange
 * updateFunction
 * @param {string} props.type Can be either "text" or "textarea"
 * @param {string} props.keyToChange
 * @param {*} props.valueToChange
 * @param {function} props.updateFunction 
 * @param {function} triggerFunction()
 * @returns 
 */
const InputField = props => {
    const [input, setInput] = useState("")
    const placeholder = useRef("")
    const valueToChange = useRef("")

    useEffect(() => {
        placeholder.current = `Edit Task-${capitalizeFirstLetter(props.keyToChange)}`
        valueToChange.current = props.valueToChange
        setInput(props.valueToChange)
    }, [props])

    function handleEnter(e) {
        props.updateFunction(props.keyToChange, input)
        props.triggerFunction && props.triggerFunction()
        valueToChange.current = input
    }

    return (
        <Form.Control
            class="task-id" //`${}` 
            bsPrefix={props.name ? props.name : ""}
            type={props.type === "text" ? "text" : ""}
            placeholder={placeholder.current}
            as={props.type ? props.type : "input"}
            value={input}
            onFocus={() => setInput(valueToChange.current)}
            onInput={e => setInput(replaceUmlaute(e.target.value))}
            onKeyDown={e => e.key === 'Enter' && (handleEnter(e))}
            onBlur={(e) => handleEnter(e)}
        />
    )
}



export default InputField