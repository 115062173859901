//Redux/ DataManagement import
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggletakeImg, setNumClicks } from '../../redux/actions';

const CameraButton = (props) => {
    const dispatch = useDispatch()
    const labelList = useSelector(state => state.labelList)
    const selectedLabelId = useSelector(state => state.selectedLabelId)

    function handleButtonClick() {
        dispatch(toggletakeImg());
        dispatch(setNumClicks(selectedLabelId))
    }
 
    function handleSpace(e) {
        e.code === "Space" && handleButtonClick()
    }

    return (
        <div
            id="camera-button-wrapper" className="full-size-parent"
            tabIndex={0}
            onKeyDown={e => handleSpace(e)}>
            <div
                className="photo-button"
                onClick={() => handleButtonClick()} >
                <div className="circle" >
                    {labelList.find(label => label.id === selectedLabelId) ? labelList.find(label => label.id === selectedLabelId).numClicks : ""}</div>
                <div className="ring"></div>
            </div>
        </div>
    )
}

export default CameraButton;