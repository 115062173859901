export const connectionStatus = (state = "not-connected", action) => {
    switch (action.type) {
        case "SET_CONNECTION_STATUS":
            return action.payload;
        default:
            return state;
    }
}

export const selectedCamera = (state = "", action) => {
    switch (action.type) {
        case "SET_ACTIVE_CAMERA":
            return action.payload;
        default:
            return state;
    }
}

export const availableCameraDevices = (state = [], action) => {
    switch (action.type) {
        case "SET_AVAILABLE_CAMERA_DEVICES":
            return action.payload;
        default:
            return state;
    }
}
export const cameraFacingMode = (state="", action) => {
    switch (action.type) {
        case "SET_CAMERA_FACING_MODE":
            return action.payload;
        default:
            return state;
    }
}
