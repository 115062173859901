import { useEffect, useRef, useState } from 'react';
import { correctInput, generateId, replaceUmlaute } from '../../helpers/utilities';
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLabelId, setTrainingState } from '../../redux/actions';
import trainingNames from "../../trainingState.json"

export const AddLabelButton = (props) => {
    const trainingStateNames = trainingNames
    const dispatch = useDispatch()
    const [input, setInput] = useState("+")
    const [zoom, setZoom] = useState(false)
    const startTraining = useSelector(state => state.startTraining)
    const trainingState = useSelector(state => state.trainingState)
    const [disabled, setDisabled] = useState(false)

    function handleEnter(labelName) {
        if (labelName !== "") {
            const id = generateId();
            props.addLabel({
                id: id,
                label: correctInput(labelName, true),
                new: true
            });
        }
    }

    useEffect(() => {
        switch (trainingState) {
            case trainingStateNames.training:
                setDisabled(true)
                break
            case trainingNames.finished:
                setDisabled(false)
                dispatch(setTrainingState(trainingNames.waiting))
                break
            default:
        }
    }, [trainingState])

    useEffect(() => {
        if (zoom) {
            setTimeout(function () {
                window.scrollTo(
                    {
                        top: 0, left: 0, ScrollBehavior:'smooth'}
                )
            }, 500)
        }
    }, [zoom])


    return (
        <Form
            id="add-part-button"
            class={zoom ? 'zoom' : ''}
            onBlur={(e) => (setZoom(false), setInput("+"))}
        >
            <Form.Control
                id={`add-part-button__input`}
                class={`${zoom ? "max" : ""} ${disabled ? "disabled" : ""}`}
                type="text"
                as="input"
                value={correctInput(input)}
                onClick={(e) => {
                    dispatch(setSelectedLabelId(-1))
                    setZoom(true)
                    setInput("")
                }}
                disabled={disabled} // TODO Check right position
                onInput={e => setInput(correctInput(e.target.value, false))}
                onKeyDown={e => e.key === 'Enter' && (handleEnter(input), e.target.blur())}
            />
            {zoom && <Button
                id="add-part-button__submit"
                variant="secondary"
                type="submit"
                onMouseDown={(e) => {
                    handleEnter(input);
                    setZoom(false);
                    e.stopPropagation();
                }}
            >
                Save
            </Button>}
        </Form>
    )
} 
