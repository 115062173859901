//React Components import
import React, { useState, useEffect, useRef } from 'react';
import simplePeer from '../helpers/simple-peer';
//Redux/ DataManagement import
import io from 'socket.io-client';
import { extractModelName, generateId, getTimeStamp, setupRequiredModelData } from '../helpers/utilities';
import { useSelector, useDispatch } from 'react-redux';
import {
  addLabelToLabelList,
  rendered,
  setCurrentLabel,
  toggletakeImg,
  toggleStartTraining,
  setTrainingFinished,
  setRemoveLabelFromServer,
  setRemoveClassModelOnServer,
  setDetectionBoundingBoxPositions,
  setAvailableDetectionModels,
  setAvailableClassificationModels,
  setUserName,
  setLabelList,
  setJobList,
  requestWorkPlans,
  setAnswerOnSave,
  toggleJobListTransfer,
  setFetchedWorkPlans,
  toggleEditedJobTransfer,
  setEditedJob,
  toggleDeletedJobTransfer,
  setDeletedJob,
  setConnectionStatus,
  setTrainingState,
  resetNumClicks,
  setActiveCamera,
  setCameraFacingMode
} from '../redux/actions';
import BoundingBoxes from './detecBoundingBox';
import { initRequiredModelData } from '../redux/actions';
import { useParams } from 'react-router';
import  trainingNames from "../trainingState.json"

export default function VideoFeed(props) {
  const dispatch = useDispatch()
  const params = useParams()
  const trainingStateNames = trainingNames
  var userName = props.userName ? props.userName : params.userName;
  var showVideoFeed = props.showVideoFeed ? props.showVideoFeed : true;
  const roomId = userName === "Frontend" ? "Webapp_room1" : `KimoknowRoom_${userName}`;
  const remoteName = userName === "Frontend" ? "Backend" : `Backend_${userName}`;

  const signalingServerURL = "https://devpeerconnect2.kimoknow.de"

  // ref storages for the peer conntection
  const peer = useRef({});
  const track = useRef({});
  const localStream = useRef();
  const localVideo = useRef();
  const remoteVideo = useRef();
  const socket = useRef(io(signalingServerURL));
  const initiator = useRef(false); // this MUST be true for peer to signal

  const [connectedToPeer, setConnectToPeer] = useState(false)

  // use redux state management for calls to peer connection which may be triggered from outside
  const takeImg = useSelector(state => state.takeImg)
  const drawBoundingBox = useSelector(state => state.drawBoundingBox)
  const boundingBoxPosition = useSelector(state => state.boundingBoxPosition)
  const selectedLabelId = useSelector(state => state.selectedLabelId)
  const startTraining = useSelector(state => state.startTraining)
  const highlightThreshold_classification = useSelector(state => state.highlightThreshold_classification)
  const highlightThreshold_detection = useSelector(state => state.highlightThreshold_detection)
  const removeLabelOnServer = useSelector(state => state.removeLabelOnServer)
  const removeClassModelOnServer = useSelector(state => state.removeClassModelOnServer)
  const classificationModel = useSelector(state => state.classificationModel)
  const detectionModel = useSelector(state => state.detectionModel)
  const numTrainSteps = useSelector(state => state.numTrainSteps)
  const editedJob = useSelector(state => state.editedJob)
  const sendEditedJobToServer = useSelector(state => state.sendEditedJobToServer)
  const labelList = useSelector(state => state.labelList)
  const renameRemoteLabel = useSelector(state => state.renameRemoteLabel)
  const deletedJob = useSelector(state => state.deletedJob)
  const sendDeletedJobToServer = useSelector(state => state.sendDeletedJobToServer)
  const selectedCamera=useSelector(state=>state.selectedCamera)
  const cameraFacingMode=useSelector(state=>state.cameraFacingMode)

  const requestJobListFromServer = useSelector(state => state.requestJobListFromServer)
  const requestedWorkplansFromServer = useSelector(state => state.requestedWorkplansFromServer)

  // ref storages for component internal handling
  const highlightThreshold_detectionLocal = useRef(0.7)
  const highlightThreshold_classificationLocal = useRef(0.7)
  const lastLabel = useRef({ "label": "", "id": -1, "conf": 0 })
  const renders = useRef(0);
  const lastClassificationModel = useRef("")
  const lastDetectionModel = useRef("")
  const localLabelList = useRef([])
  const SimplePeer = new simplePeer(userName); // basic wrapper for the SimplePeer Libary

  const setupPeer = remoteName => {
    console.log("Connecting to peer")
    dispatch(setConnectionStatus("connecting")
    )
    //configuring the peerConnection
    SimplePeer.init(
      localStream.current,
      initiator.current,
    );
    const peerObject = SimplePeer.getPeer()
    track.current=SimplePeer.getTrack()

    peerObject.on('signal', data => {
      console.log(userName + ' send peer signal')
      const signal = {
        name: userName,
        roomId: roomId,
        remoteName: remoteName,
        desc: data
      };
      socket.current.emit('signal', signal);
    });

    peerObject.on('stream', stream => {
      console.log("KimoknowDetectionServer got stream.")
      remoteVideo.current.srcObject = stream;
    });

    peerObject.on('error', (err) => {
      if (err.code === "ERR_CONNECTION_FAILURE") {
        dispatch(setConnectionStatus("not-connected"))
      }
      console.log(err);
    });
    socket.current.on('close', () => {
      console.log("Closed")
    })

    /* when connection was established sending a welcome message 
    and setting up the models running on the backendServer*/
    peerObject.on('connect', () => {
      setConnectToPeer(true)
      dispatch(setConnectionStatus("connected"))
      peerObject.send(JSON.stringify({
        "type": "welcome",
        "data": "Here is a message from your Internet Browser"
      }))
      peerObject.send(JSON.stringify({ "type": "select_classification_model", "data": "default.kcmodel" })) //here hardcoded - should be setup in redux
      peerObject.send(JSON.stringify({ "type": "select_detection_model", "data": "default.kmodel" })) //here hardcoded - should be setup in redux
    })
    peerObject.on('data', data => {
      var jsonData = JSON.parse(data)
      jsonData.type !== "labeldata" && console.log("******jsonData.type: ", jsonData.type)
      if (jsonData.type === "welcome") {
        console.log(jsonData.data)
      } else if (jsonData.type === "labelList") {
        console.log(`${jsonData.type}:${jsonData.data}`)
        handleNewLabelList(jsonData.data)
      } else if (jsonData.type === "modelLoaded") {
        console.log("NewModelLoaded:", jsonData.data)
      } else if (jsonData.type === "availableModels_detection") {
        console.log("Received detection model list: ", jsonData.data)
        dispatch(setAvailableDetectionModels(jsonData.data))
      } else if (jsonData.type === "availableModels_classification") {
        console.log("Received class model list: ", jsonData.data)
        dispatch(setAvailableClassificationModels(jsonData.data))
      } else if (jsonData.type === "trainingStatus") {
        if (jsonData.data === "trainingFinished") {
          dispatch(setTrainingFinished(true))
          dispatch(setTrainingState(trainingStateNames.finished))
          dispatch(resetNumClicks())
        } else if (jsonData.data === "trainingStarted") {
          dispatch(setTrainingFinished(false))
        }
      } else if (jsonData.type === "labeldata") {
        if (jsonData.data.type === "detection") {
          const receivedBoxes = jsonData.data.data;
          const detectionBoxes = receivedBoxes.filter(detectionObject => parseFloat(detectionObject.conf) > highlightThreshold_detectionLocal.current);
          // console.log("Checked",parseFloat(detectionBoxes[0].conf),">",highlightThreshold_detectionLocal.current)
          dispatch(setDetectionBoundingBoxPositions(detectionBoxes))
        } if (jsonData.data.type === "classification") {
          let receivedLabelData = { "label": jsonData.data.label, "conf": jsonData.data.conf }
          // if (parseFloat(jsonData.data.conf) >= highlightThreshold_classificationLocal.current) {
          receivedLabelData["id"] = localLabelList.current?.find(label => receivedLabelData.label === label.label)?.id
          lastLabel.current = receivedLabelData
          dispatch(setCurrentLabel(receivedLabelData))
          // if (jsonData.data.type === "detection" || jsonData.data.type === "classification") {
          // console.log(jsonData.data)
          //}

          // } else {
          //   lastLabel.current = ""
          //   dispatch(setCurrentLabel(lastLabel.current))
          // } //when the confidence should be used to set a label in redux to reduce renderings
        }
        peerObject.send(JSON.stringify({
          "type": "confirm",
          "data": "labelReceived"
        }))
        // Receive Data for GuideCardSlider
      } else if (jsonData.type === "jobList") {
        dispatch(setJobList(jsonData.data))
      } else if (jsonData.type === "workplans") {
        dispatch(setFetchedWorkPlans(jsonData.data))
        let workplansWithoutClassModelPath = extractModelName(jsonData.data)
        dispatch(initRequiredModelData(setupRequiredModelData(workplansWithoutClassModelPath)))
      } else if (jsonData.type === "jobEdited") {
        dispatch(setAnswerOnSave(jsonData.data))
      }
    })
    peer.current = peerObject;
  }
  const connectToPeer = otherId => {
    console.log("Peer Connection")
    SimplePeer.connect(otherId);
  };
  // component did mount - after fetching data from db set up the socket to connect to the signalling server
  useEffect(() => {
    dispatch(setUserName(userName))
    console.log(`${userName} logged in`)
    console.log(`Establishing connection ${signalingServerURL}`)
    console.log(`Gererated socket ${socket}`)
    var video = document.getElementById("localVideo");
    video.setAttribute("playsinline", true); // to show the signal on iPhone

      getUserMedia(selectedCamera.deviceId,cameraFacingMode).then(() => {
        console.log(`LOGIN:${userName} is logging in.`)
        socket.current.emit('login', { name: userName, remoteName: remoteName, roomId: roomId });
      });

      socket.current.on('ready', () => {
        console.log(`READYTOPAIR:${userName} is ready to pair.`)
        initiator.current = true;
        setupPeer(remoteName);
      });

      socket.current.on('desc', data => {
        if (data.desc.type === 'offer' && initiator.current) return;
        if (data.desc.type === 'answer' && !initiator.current) return;
        connectToPeer(data.desc);
      });
      socket.current.on('disconnected', () => {
        setConnectionStatus("not-connected")
        initiator.current = true;
      });
  }, [])


  // component did render
  /* on every external setting of a redux state which should affect the server 
  send command via datachannel to the server to show a reaction */
  useEffect(() => {

    dispatch(rendered())
    if (takeImg) {
      !drawBoundingBox ?
        peer.current.send(JSON.stringify({
          "type": "takeImg",
          "data": {
            "label": labelList.find(label => label.id === selectedLabelId)?.label,
            "boundingBox": { "xmin": -1, "ymin": -1, "xmax": -1, "ymax": -1 }
          }
        })
        )
        : peer.current.send(JSON.stringify({
          "type": "takeImg",
          "data": {
            "label": labelList.find(label => label.id === selectedLabelId)?.label,
            "boundingBox": boundingBoxPosition
          }
        }))
      dispatch(toggletakeImg())
    }

    if (startTraining && connectedToPeer) {
      console.log("StartTrainingWithTimeStamp", getTimeStamp())
      peer.current.send(JSON.stringify({
        "type": "startTraining",
        "data": {
          "type": "classification",
          "model": "WebQuickTrainModel",
          "numTrainSteps": numTrainSteps,
          "user": userName, "timestamp": getTimeStamp()
        }
      }))
      dispatch(toggleStartTraining())
      dispatch(setTrainingState(trainingStateNames.training))
    } else if (startTraining && !connectedToPeer) {
      console.log("TrainStartError: Peer not connected. No training with timeStamp:", getTimeStamp())
    }
    if (removeLabelOnServer !== "" && connectedToPeer) {
      peer.current.send(JSON.stringify({
        "type": "removeLabel",
        "data": removeLabelOnServer
      }))
      dispatch(setRemoveLabelFromServer(""))
    } else if ((removeLabelOnServer !== "" && !connectedToPeer))
      console.log(`DELETELABELERROR: Peer not connected. Label: ${removeLabelOnServer} was only deleted from WebInterface`)
    dispatch(setRemoveLabelFromServer(""))
    if (removeClassModelOnServer !== "" && connectedToPeer) {
      peer.current.send(JSON.stringify({
        "type": "delete_classification_model",
        "data": removeClassModelOnServer + ".kcmodel"
      }))
      dispatch(setRemoveClassModelOnServer(""))
    } else if ((removeClassModelOnServer !== "" && !connectedToPeer))
      console.log(`DELETECLASSMODELERROR: Peer not connected. Model: ${removeClassModelOnServer} was only deleted from WebInterface`)
    dispatch(setRemoveClassModelOnServer(""))
    if (highlightThreshold_classification !== highlightThreshold_classificationLocal.current) {
      highlightThreshold_classificationLocal.current = highlightThreshold_classification
    }
    if (highlightThreshold_detection !== highlightThreshold_detectionLocal.current) {
      highlightThreshold_detectionLocal.current = highlightThreshold_detection
    }
    if (lastClassificationModel.current !== classificationModel && connectedToPeer) {
      console.log("Select Classification Model: ", classificationModel)
      peer.current.send(JSON.stringify({ "type": "select_classification_model", "data": classificationModel}))
      lastClassificationModel.current = classificationModel
      console.log("New classification model set:", lastClassificationModel.current)
    } else if (lastClassificationModel.current !== classificationModel && !connectedToPeer) {
      console.log("SET:MODEL_ERROR_classification: Peer not connected")
      lastClassificationModel.current = classificationModel
      console.log("Classification model is:", lastClassificationModel.current, classificationModel)
    }
    if (lastDetectionModel.current !== detectionModel && connectedToPeer) {
      peer.current.send(JSON.stringify({ "type": "select_detection_model", "data": detectionModel + ".kmodel" })) //here hardcoded - should be setup in redux
      lastDetectionModel.current = detectionModel
      console.log("New detection model set:", lastDetectionModel.current)
    } else if (lastDetectionModel.current !== detectionModel && !connectedToPeer) {
      console.log("SET_DETECTION_MODEL_ERROR_detection: Peer not connected")
      lastDetectionModel.current = detectionModel
      console.log("Detection model is:", lastDetectionModel.current, detectionModel)
    }
    // Data Requests for GuidCardSlider
    if (requestJobListFromServer && connectedToPeer) {
      dispatch(toggleJobListTransfer())
      console.log("Send getJobList: ", requestJobListFromServer)
      peer.current.send(JSON.stringify({
        "type": "getJobList", "data": { "userName": userName }
      }))
    }
    if (requestedWorkplansFromServer && requestedWorkplansFromServer.length && connectedToPeer) {
      console.log("Send getWorkPlan: ", requestedWorkplansFromServer)
      peer.current.send(JSON.stringify({
        "type": "getWorkplans", "data": { "userName": userName, "fileNames": requestedWorkplansFromServer }
      }))
      dispatch(requestWorkPlans([]))
    }
    if (sendEditedJobToServer && Object.keys(editedJob).length !== 0) {
      peer.current.send(JSON.stringify({
        "type": "editJob", "data": { "userName": userName, "fileName": editedJob.fileName, "jobData": editedJob.jobData, "editingStatus": "" }
      }))
      dispatch(toggleEditedJobTransfer())
      dispatch(setEditedJob({}))
    }
    if (toggleDeletedJobTransfer && Object.keys(deletedJob).length !== 0) {
      peer.current.send(JSON.stringify({
        "type": "deleteJob", "data": { "userName": userName, "fileName": deletedJob.fileName }
      }))
      dispatch(toggleDeletedJobTransfer())
      dispatch(setDeletedJob({}))
    }
    if (renameRemoteLabel && renameRemoteLabel !== "") {
      peer.current.send(JSON.stringify({
        "type": "renameLabel", 
        "data": renameRemoteLabel
      }))
    }
  }
  )
  
  useEffect(()=>{
    if(selectedCamera.deviceId){
    getUserMedia(selectedCamera.deviceId,"").then(()=>{
      console.log(`Set camera to: ${selectedCamera.label} ${selectedCamera.deviceId}`)
      dispatch(setCameraFacingMode(""))
    })}
  },[selectedCamera])
  useEffect(()=>{
    if(cameraFacingMode){
    getUserMedia("",cameraFacingMode).then(()=>{
      console.log(`Set camera facing mode to: ${cameraFacingMode}`)
      dispatch(setActiveCamera(""))
    })}
  },[cameraFacingMode])

  const getUserMedia = (cameraDeviceId,cameraFacingMode) => new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia = (navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia);
    const op = {
      video: {
        width: { min: 160, ideal: 640, max: 1280 },
        height: { min: 120, ideal: 360, max: 720 },
        facingMode: cameraFacingMode?cameraFacingMode:"environment", // frontCamera: "user", backCamera: "environment"
        deviceId:cameraDeviceId?cameraDeviceId:""
      },

      // require audio
      audio: false
    };
    navigator.mediaDevices.getUserMedia(op)
      .then(stream => {
        if(connectedToPeer){
          const newTrack=stream.getVideoTracks()[0]
          if(track.current&&track.current!==newTrack){
            peer.current.replaceTrack(track.current,newTrack,localStream.current)
            track.current=newTrack
            localStream.current.getVideoTracks().forEach(videoTrack=>{
              console.log("Stopping",videoTrack)
              videoTrack.stop()
            })
          }
        }
        props.setVideoStream && props.setVideoStream(stream)
        
        if(!localStream.current){
          console.log("LocalStream has been set")
          localStream.current = stream;
        }
        localVideo.current.srcObject = stream;
        localVideo.current.setAttribute("playsinline", true);
        resolve();
      })
      .catch(err => console.log(err) || reject(err))
  });

  const handleNewLabelList = (data) => {
    let labelListWithoutIds = [...new Set([...data.labels, ...data.all_labels])]
    const newLabelList = labelListWithoutIds.map(label => { 
      return { 
        id: label.id ? label.id : generateId(), 
        label: label, 
        numClicks: 0 
      }});
    console.log("Received New Labellist: ", newLabelList);
    dispatch(setLabelList(newLabelList));
    localLabelList.current = newLabelList
  }


  return (
    <div id="video-stream" className={`full-size-parent`}>
      {/* {console.log(props.match.params.userName)} */}
      {<video
        autoPlay
        id='localVideo'
        muted
        ref={localVideo}
      >
        {console.log(`VideoFeed Rendered: ${renders.current++}`)}
      </video>}
      {/* <BoundingBoxes videoId="localVideo" videoWrapperId="video-stream" /> */}
    </div>
  );
}

