//Style import
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as KKLogoMaennchen } from '../resource/KK_Logo_Maennchen.svg';
import { ReactComponent as EditIcon } from "../resource/icon_gear_settings.svg"
import { ReactComponent as AppIcon } from '../resource/icon_apps_f.svg';
import { ReactComponent as QuickTrainerIcon } from "../resource/icon_quicktrainer.svg";
import { ReactComponent as JobAssistantIcon } from "../resource/icon_jobassistant.svg";
import { ReactComponent as RocketIcon } from "../resource/icon_rocket.svg";
import { ReactComponent as HourGlassIcon } from "../resource/icon_hourglass.svg";
import { ReactComponent as SelectionIcon } from "../resource/icon_selection.svg";


//React Components import
import React, { useState, useEffect, useRef } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
//Redux/ DataManagement import
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter, correctInput, replaceUmlaute } from '../helpers/utilities';
import {
  rendered,
  sethighlightThreshold_classification,
  sethighlightThreshold_detection,
  toggleScrollToLabel,
  toggleStartTraining,
  toggleStartTrainingOnDeselect,
  setClassificationModel,
  setDetectionModel,
  setNumTrainSteps,
  setSelectedLabel,
  clearNumClicks,
  addClassModelToRequiredModelData,
  setRemoveClassModelOnServer,
  setActiveCamera,
  setCameraFacingMode
} from '../redux/actions';
import { startTraining } from '../redux/reducers/peerCommands';
import Slider from '@material-ui/core/Slider';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { availableDetecionModels } from '../redux/reducers/labels';
import { concatRequiredAndAvailableClassModels } from '../helpers/jobHelpers';
import { DeleteComponent } from './deleteComponent';
import routing from "../routing.json";

export function NavMenu() {
  const renders = useRef(0);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(rendered())
  })
  return (
    <Navbar>
      {console.log(`NavMenu Rendered: ${renders.current++}`)}
      <NavItem icon={<EditIcon />} >
        <DropdownMenu></DropdownMenu>
      </NavItem>

    </Navbar>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar_kk">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);
  const trainingFinished = useSelector(state => state.trainingFinished)
  return (
    <li className="nav-item_kk">
      {trainingFinished ? "" : <div className="loader"></div>}
      <span className="icon-button" onClick={() => setOpen(!open)}>
        {props.icon}
      </span>

      {open && props.children}
    </li>
  );
}

function DropdownMenu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const routes = routing

  // Redux States
  const scrollToLabel = useSelector(state => state.scrollToLabel)
  const startTrainingOnDeselect = useSelector(state => state.startTrainingOnDeselect)
  const trainingFinished = useSelector(state => state.trainingFinished)
  const drawBoundingBox = useSelector(state => state.drawBoundingBox)
  const highlightThreshold_classification = useSelector(state => state.highlightThreshold_classification)
  const highlightThreshold_detection = useSelector(state => state.highlightThreshold_detection)
  const availableClassificationModels = useSelector(state => state.availableClassificationModels)
  const availableDetectionModels = useSelector(state => state.availableDetecionModels)
  const numTrainSteps = useSelector(state => state.numTrainSteps)
  const userName = useSelector(state => state.userName)
  const requiredModelData = useSelector(state => state.requiredModelData)
  const availableCameraDevices=useSelector(state => state.availableCameraDevices)
  const selectedCamera=useSelector(state=>state.selectedCamera)
  const cameraFacingMode=useSelector(state=>state.cameraFacingMode)

  // Local States
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const [classModelList, setClassModelList] = useState([])
  const [addModelInput, setAddModelInput] = useState("")
  // Refs
  const dropdownRef = useRef(null);
  // Constants
  const checkMarkOn = "✓"
  const checkMarkOff = "⨉"
  const addModelPlaceholder = "Add new model"

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
  }, [])

  useEffect(() => {
    let concatList = concatRequiredAndAvailableClassModels(availableClassificationModels, requiredModelData)
    setClassModelList(concatList)
  }, [availableClassificationModels])

  function calcHeight(el) {
    const height = el.offsetHeight + 5;
    setMenuHeight(height);
  }

  function updateHeight() {
    let height = dropdownRef.current.firstChild.offsetHeight
    setMenuHeight(height);
  }

  useEffect(() => {
    console.log("menuHeight: ", menuHeight)
    console.log("dropdownRef: ", dropdownRef.current.firstChild.offsetHeight)
    let height = dropdownRef.current.firstChild.offsetHeight
    setMenuHeight(height);
  }, [classModelList])

  function DropdownItem(props) {
    return (
      <span className={`menu-item ${props.appendix ? props.appendix : ""}`} onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
        {props.leftIcon !== "none" &&
          <span className="icon-button">{props.leftIcon}</span>}
        {props.children}
        {props.rightIcon !== "none" &&
          <span className="icon-right">{props.rightIcon}</span>
        }      </span>
    );
  }

  const ModelSelector = (props) => {
    const dispatch = useDispatch()

    const classificationModel = useSelector(state => state.classificationModel);
    const detectionModel = useSelector(state => state.detectionModel);

    const [existsInBackend, setExistsInBackend] = useState(true)
    const [selected, setSelected] = useState(false)
    let [searchParams, setSearchParams] = useSearchParams();

    const checkMarkOn = "✓"

    useEffect(() => {
      if (props.modelType === "classification") {
        !availableClassificationModels.some(models => models.name === props.modelName) && setExistsInBackend(false)
      }
    }, [availableClassificationModels])

    useEffect(() => {
      if (props.modelType === "classification") {
        props.modelName === classificationModel && setSelected(true)
      } else if (props.modelType === "detection") {
        props.modelName === detectionModel && setSelected(true)
      }
    }, [classificationModel, detectionModel])

    const deleteModel = (modelName) => {
      modelName === classificationModel && dispatch(setClassificationModel("default"))
      dispatch(setRemoveClassModelOnServer(modelName))
    }

    const handleClick = (e) => {
      props.modelType === "classification" ? dispatch(setClassificationModel(props.modelName)) :
        props.modelType === "detection" && dispatch(setDetectionModel(props.modelName))
      if (location.pathname.includes(routes.quickTrainer)) {
        let searchParam = routes.searchParams.classModelIDParam
        setSearchParams({ [searchParam]: props.modelName })
      }
    }
    return (
      <div onClick={handleClick}>
        <DropdownItem
          appendix={`classModel_${existsInBackend ? "trained" : "untrained"}`}
          leftIcon={selected ? checkMarkOn : ""}  // props.modelName.charAt(0).toUpperCase()
          rightIcon={(props.modelName !== "default" && existsInBackend) &&
            <DeleteComponent updateFunction={deleteModel} deleteKey={props.modelName} />
          }
        >
          {props.modelName}
        </DropdownItem>
      </div>
    )
  }

  const updateModelList = (newModelName) => {
    if (newModelName !== "") {
      //dispatch(addClassModelToRequiredModelData({ "classModel": newModelName }))
      dispatch(setClassificationModel(newModelName))
    }
  }

  const handleChangeClassificationThreshold = (value) => { }


  return (
    <div className="dropdown_nav" style={{ height: menuHeight }} ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="setting_focusLabelBar">
          <div onClick={() => navigate(`/${userName}/${routes.homePage}`)}>
            <DropdownItem
              leftIcon={<KKLogoMaennchen />} >
              Home Page
            </DropdownItem>
          </div>
          <DropdownItem
            goToMenu="applications"
            leftIcon={<AppIcon id="apps-icon" />} >
            Applications
          </DropdownItem>
          <DropdownItem
            leftIcon={<EditIcon class="white" />}
            goToMenu="detectionSettings">
            Detection Settings
          </DropdownItem>
          <DropdownItem
            leftIcon={<SelectionIcon />}
            goToMenu="models">
            Pretrained Models
          </DropdownItem>
          <DropdownItem
            leftIcon="🔧"
            goToMenu="generalSettings">
            General Settings
          </DropdownItem>
          <div onClick={() => trainingFinished && dispatch(toggleStartTraining())}>
            <DropdownItem leftIcon={trainingFinished ? <RocketIcon /> : <HourGlassIcon />}>
              {trainingFinished ? "Start Training" : "Training is running"}
            </DropdownItem>
          </div>

        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'applications'}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon="⇦">
            <h2>Main</h2>
          </DropdownItem>
          <div onClick={() => navigate(`/${userName}/${routes.quickTrainer} `)}>
            <DropdownItem
              leftIcon={<QuickTrainerIcon class="orange" />} >
              QuickTrainer
            </DropdownItem>
          </div>
          <div onClick={() => navigate(`/${userName}/${routes.jobAssistant} `)}>
            <DropdownItem
              leftIcon={<JobAssistantIcon class="orange" />} >
              JobAssistant
            </DropdownItem>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'models'}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon="⇦">
            <h2>Main</h2>
          </DropdownItem>
          {/* <DropdownItem leftIcon="➕"> */}
          <Form.Control
            bsPrefix='add-model-input'
            type="text"
            placeholder={addModelPlaceholder}
            value={addModelInput}
            onInput={(e) => setAddModelInput(correctInput(e.target.value, false))}
            onKeyDown={e => e.key === 'Enter' && !classModelList.some(model => model.name === addModelInput)
              && (updateModelList(correctInput(addModelInput, true)), setAddModelInput(""),
                e.target.blur())} />

          {/* </DropdownItem> */}
          {classModelList.map((model, index) => {
            return (
              <ModelSelector key={model.name} modelName={model.name} updateHeight={updateHeight} modelType="classification" />
            )
          })}
          <DropdownItem goToMenu="detection" leftIcon="🔲">
            Detection
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'detection'}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div>
          <DropdownItem goToMenu="models" leftIcon="⇦">
            <h2>Pretrained Models</h2>
          </DropdownItem>
          {availableDetectionModels.map((model) => {
            return (
              <ModelSelector key={model} modelName={capitalizeFirstLetter(model)} modelType="detection" />
            )
          })}
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'detectionSettings'}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}>
        <div className="menu">
          <DropdownItem goToMenu="main" leftIcon="⇦">
            <h2>Main</h2>
          </DropdownItem>
          <div onClick={() => dispatch(toggleScrollToLabel())}><DropdownItem leftIcon={scrollToLabel ? checkMarkOn : checkMarkOff}>Scroll to detected label</DropdownItem></div>
          <div onClick={() => dispatch(toggleStartTrainingOnDeselect())}><DropdownItem leftIcon={startTrainingOnDeselect ? checkMarkOn : checkMarkOff}>Start Training on deselect</DropdownItem></div>
          {/* <div onClick={() => dispatch(toggleDrawBoundingBoxMode()) }><DropdownItem leftIcon={drawBoundingBox ? checkMarkOn : checkMarkOff}>Draw BoundingBoxes</DropdownItem></div> */}
          <DropdownItem leftIcon="clTh" rightIcon={highlightThreshold_classification}><div className="flexSliderLayout"><Slider id="thresholdSlider" orientation="horizontal" value={highlightThreshold_classification} onChange={(event, value) => { dispatch(sethighlightThreshold_classification(value)) }} aria-labelledby="continuous-slider" max={1} min={0} step={0.01} /></div></DropdownItem>
          <DropdownItem leftIcon="deTh" rightIcon={highlightThreshold_detection}><div className="flexSliderLayout"><Slider id="thresholdSlider" orientation="horizontal" value={highlightThreshold_detection} onChange={(event, value) => { dispatch(sethighlightThreshold_detection(value)) }} aria-labelledby="continuous-slider" max={1} min={0} step={0.01} /></div></DropdownItem>
          <DropdownItem leftIcon="Steps" rightIcon={numTrainSteps}><div className="flexSliderLayout"><Slider id="thresholdSlider" orientation="horizontal" value={numTrainSteps} onChange={(event, value) => { dispatch(setNumTrainSteps(value)) }} aria-labelledby="continuous-slider" max={5000} min={500} step={10} /></div></DropdownItem>
        </div>
      </CSSTransition>
      <CSSTransition
    in={activeMenu === 'generalSettings'}
    timeout={500}
    classNames="menu-secondary"
    unmountOnExit
    onEnter={calcHeight}>
    <div className="menu">
      <DropdownItem goToMenu="main" leftIcon="⇦">
        <h2>Main</h2>
      </DropdownItem>
      {availableCameraDevices.map((cameraDevice,index) => {
            return (
              <div onClick={() => dispatch(setActiveCamera(cameraDevice))}><DropdownItem key={cameraDevice.deviceId} leftIcon={selectedCamera.deviceId===cameraDevice.deviceId?"📸":"📷"} rightIcon="">{cameraDevice.label?cameraDevice.label:`Camera ${index+1}`}</DropdownItem></div>
            )
          })}
    <div onClick={() => dispatch(setCameraFacingMode(cameraFacingMode==="environment"?"user":cameraFacingMode?"environment":"user"))}>
        <DropdownItem key="cameraFacingMode" leftIcon={cameraFacingMode==="environment"?"🌄":cameraFacingMode?"🤳":"🔁"}>
        {cameraFacingMode==="environment"?"Environment Cam":cameraFacingMode?"Selfie Cam":"Switch cameraface"}
      </DropdownItem>
      </div>
    </div>
  </CSSTransition>    

</div>


  );
}

export default NavMenu
