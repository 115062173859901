//React Components import
import React, { useState, useEffect, useRef } from 'react';
//Redux/ DataManagement import
import { useDispatch, useSelector } from 'react-redux';
import {
    rendered,
    removeLabelFromLabelList,
    changeLabelName,
    setSelectedLabelId,
    toggleStartTraining,
    setRemoveLabelFromServer,
    changeRemoteName
} from '../../redux/actions';
import { replaceUmlaute } from '../../helpers/utilities';


function Label(props) {
    const { _, renders } = props;
    const dispatch = useDispatch()

    // Global States
    const currentLabel = useSelector(state => state.currentLabel);
    const classificationThreshold = useSelector(state => state.highlightThreshold_classification)
    const labelList = useSelector(state => state.labelList);
    const scrollToLabel = useSelector(state => state.scrollToLabel);
    const startTrainingOnDeselect = useSelector(state => state.startTrainingOnDeselect);
    const selectedLabelId = useSelector(state => state.selectedLabelId);
    const requiredModelData = useSelector(state => state.requiredModelData)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const classificationModel = useSelector(state => state.classificationModel)

    // Local States
    const [labelText, setLabelText] = useState("");
    const [labelTrained, setLabelTrained] = useState(true);
    const [highlighted, setHighlighted] = useState(false)

    const [labelId, setLabelId] = useState()
    const [showFloatingInput, setShowFloatingInput] = useState(false)
    // Refs
    const labelInput = useRef(null)
    const labelIsNew = useRef(false)
    const oldName = useRef("")

    useEffect(() => {
        if (props.label) {
            setLabelId(props.label.id)
            setLabelText(props.label.label.toLowerCase())
            labelIsNew.current = props.label.new ? true : false
            oldName.current = props.label.label.toLowerCase()
        }
    }, [])

    useEffect(() => {
        let trainedLabels = availableClassificationModels.find(
            model => model.name === classificationModel.toLowerCase()
        )
        if (trainedLabels && trainedLabels.labels && props.label.label) {
            trainedLabels.labels.includes(props.label.label) ? setLabelTrained(true) : setLabelTrained(false)
        }
    }, [labelList])

    useEffect(() => {
        dispatch(rendered())
        renders()
        if (labelId) {
            if ((labelId === selectedLabelId) || (currentLabel.id && labelId === currentLabel.id && scrollToLabel && currentLabel.conf >= classificationThreshold)) {
                const labelIndex = labelList.findIndex(value => value.id === labelId)
                document.getElementById("label-bar__classification-labels").scrollLeft = document.getElementById("label-bar__classification-labels").scrollWidth * labelIndex / labelList.length;
            }
        }
        if (labelIsNew.current) {
            labelIsNew.current = false
        }
    })

    const handleNewTextInput = (text) => {
        if (text === "") { text = " " }
        text = replaceUmlaute(text).trim()
        if (oldName.current != text) {
            setLabelText(text)
            oldName.current = text
            dispatch(changeLabelName(labelId, text))
            dispatch(setSelectedLabelId(labelId))//so that the focus on the renamed label is not lost
            dispatch(changeRemoteName(labelId, text))
        }
    }
    const handleRemoveClick = () => {
        dispatch(setSelectedLabelId(-1))
        dispatch(removeLabelFromLabelList(labelId))
        dispatch(setRemoveLabelFromServer(labelText.toLowerCase()))//send command to python Backend
        console.log("Removed label with ID: " + labelId);
    }
    const handleSelectionClick = () => {
        // If label already selectedLabelId, deselect. Else select it
        if (labelId === selectedLabelId) {
            dispatch(setSelectedLabelId(-1))
            if (startTrainingOnDeselect) {
                dispatch(toggleStartTraining())
            }
        } else {
            dispatch(setSelectedLabelId(labelId))
        }
    }

    useEffect(() => {
        if (labelId && currentLabel.id && labelId === currentLabel.id && currentLabel.conf >= classificationThreshold)
            setHighlighted(true)
        else {
            setHighlighted(false)
        }
    }, [currentLabel] )

    return (
        <div
            className={`cl-label ${highlighted ? "highlightedLabel" : ""} ${labelId === selectedLabelId ? "selectedLabelId" : ""} ${labelTrained ? "trained" : "untrained"}`}
        >
            <span
                className="cl-label__selection-button"
                onClick={() => handleSelectionClick()}
                onBlur={() => dispatch(setSelectedLabelId(-1))}
            >
                {labelId === selectedLabelId ? "📌" : " "}
            </span>
            <input
                id={`cl-label__input-${labelId}`}
                ref={labelInput}
                onFocus={() => labelId && labelId !== selectedLabelId && dispatch(setSelectedLabelId(labelId))}
                value={labelText}
                onInput={(e) => { setLabelText(e.target.value.toLowerCase()); }}
                onBlur={(e) => handleNewTextInput(e.target.value.toLowerCase())}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') { handleNewTextInput(e.target.value.toLowerCase()); e.target.blur(); console.log("Enter pressed") }
                }} />
            <button
                className="cl-label__remove-button"
                onClick={() => {
                    console.log("Delete Button clicked for " + labelText);
                    handleRemoveClick();
                }}>🗑️
            </button>
            {/* {showFloatingInput &&
                <FloatingInput
                    labelId={labelId}
                    handleInput={handleNewTextInput}
                    setText={setLabelText}
                    value={labelText}
                    showSelf={setShowFloatingInput} />
            } */}
        </div>
    );
}

export default Label;