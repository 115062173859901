import { act } from "react-dom/test-utils";

// REDUCER
export const labelList = (state = [], action) => {
    switch (action.type) {
        case 'ADD_LABEL':
            if (!state.some(label => label.label === action.payload.label)) {
                console.log([...state, action.payload])
                var newLabel = action.payload
                newLabel["numClicks"] = 0 
                return [...state, newLabel];
            }
            else {
                console.log(`AddingLabelFailed: ${action.payload.label} already exists`);
                return state;
            }
        case 'SET_LABELLIST':
            return action.payload;
        case 'REMOVE_LABEL':
            return state.filter((label) => label.id !== action.payload)
        case 'RENAME_LABEL':
            return state.map(label => (label.id === action.payload.labelId ? {...label, id: label.id, label: action.payload.newLabelName } : label))
        case "INCREMENT_NUM_CLICKS":
            return state.map(label=> (label.id === action.payload ? {...label, numClicks: label.numClicks+1} : label)) ;
        case "RESET_NUM_CLICKS":
            return state.map(label=> ({...label, numClicks: 0}));
        default:// need this for default case
            return state
    }
}

export const currentLabel = (state = {}, action) => {
    switch (action.type) {
        case 'NEW_LABEL':
            return action.payload;
        default: // need this for default case
            return state
    }
}
export const scrollToLabel = (state = true, action) => {
    switch (action.type) {
        case 'CHANGE_SCROLLTOLABEL_MODE':
            return !state;
        default:
            return state
    }
}
export const selectedLabelId = (state = -1, action) => {
    switch (action.type) {
        case 'SELECTED_LABEL_BY_ID':
            return action.payload;
        default:
            return state;
    }
}
export const drawBoundingBox = (state = false, action) => {
    switch (action.type) {
        case 'CHANGE_DRAWBOUNDINGBOX_MODE':
            return !state;
        default:
            return state;
    }
}
export const boundingBoxPosition = (state = {}, action) => {
    switch (action.type) {
        case 'NEW_BOUNDINGBOXPOSITION':
            return action.payload;
        default:
            return state;
    }

}
export const detectionBoundingBoxPositions = (state = [{ label: "", conf: "", bb: { xmin: 0, xmax: 0, ymin: 0, ymax: 0 } }], action) => {
    switch (action.type) {
        case "NEW_BOUNDING_BOXES":
            return action.payload;
        default:
            return state;
    }

}
export const highlightThreshold_classification = (state = 0.7, action) => {
    switch (action.type) {
        case 'NEW_CLASSIFICATION_THRESHOLD':
            return action.payload;
        default:
            return state;
    }
}
export const highlightThreshold_detection = (state = 0.7, action) => {
    switch (action.type) {
        case 'NEW_DETECTION_THRESHOLD':
            return action.payload;
        default:
            return state;
    }
}

export const availableDetecionModels = (state = [], action) => {
    switch (action.type) {
        case 'NEW_AVAILABLE_DETECTION_MODELS':
            return action.payload;
        default:
            return state;
    }
}
export const availableClassificationModels = (state = [], action) => {
    switch (action.type) {
        case 'NEW_AVAILABLE_CLASSIFICATION_MODELS':
            return action.payload;
        default:
            return state;
    }
}

export const numTrainSteps = (state = 1500, action) => {
    switch (action.type) {
        case "NEW_NUMBER_OF_TRAINING_STEPS":
            return action.payload;
        default:
            return state;
    }
}

export const userName = (state = "", action) => {
    switch (action.type) {
        case "SET_USERNAME":
            return action.payload;
        default:
            return state;
    }
}

