import { useNavigate } from 'react-router';
import { ReactComponent as KKLogoMaennchen } from '../resource/KK_Logo_Maennchen.svg';
import routing from "../routing.json"


export function HomeButton(props) {
    const navigate = useNavigate()
    const routes = routing

    return (
        <button
            onClick={() => navigate(`/${props.userName}`)}
            className="home-button">
            <KKLogoMaennchen />
        </button>
    )
}
