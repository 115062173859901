//REDUCER
import trainingNames from "../../trainingState.json"
const trainingStateNames = trainingNames

export const takeImg = (state = false, action) => {
    switch (action.type) {
        case 'TAKE_IMG':
            return !state;
        default:
            return state;
    }
}

export const startTraining = (state = false, action) => {
    switch (action.type) {
        case 'START_TRAINING':
            return !state;
        default:
            return state;
    }
}
export const trainingFinished = (state = true, action) => {
    switch (action.type) {
        case 'TRAINING_FINISHED':
            return action.payload;
        default:
            return state;
    }
}

export const trainingState = (state = trainingStateNames.waiting, action) => {
    switch (action.type) {
        case "SET_TRAINING_STATE":
            return action.payload;
        default:
            return state
    }
}

export const startTrainingOnDeselect = (state = false, action) => {
    switch (action.type) {
        case 'CHANGE_TRAINSTART_MODE':
            return !state;
        default:
            return state
    }
}
export const removeLabelOnServer = (state = "", action) => {
    switch (action.type) {
        case 'REMOVE_LABEL_FROM_SERVER':
            return action.payload;
        default:
            return state;
    }
}
export const detectionModel = (state = "default", action) => {
    switch (action.type) {
        case 'SET_DETECION_MODEL':
            return action.payload;
        default:
            return state;
    }
}
export const classificationModel = (state = "default", action) => {
    switch (action.type) {
        case 'SET_CLASSIFICATION_MODEL':
            return action.payload;
        default:
            return state;
    }
}

export const removeClassModelOnServer = (state = "", action) => {
    switch (action.type) {
        case 'REMOVE_CLASSIFICATION_MODEL_FROM_SERVER':
            return action.payload;
        default:
            return state;
    }
}

export const renameRemoteLabel = (state = "", action) => {
    switch (action.type) {
        case 'RENAME_REMOTE_LABEL':
            return action.payload;
        default:
            return state;
    }
}