
/**
 * Function generates a new empty part template based on the specifications outlined in the GitLab Wiki.
 * @returns An empty part template according to the jobFormat in GitLabWiki
 */
export function getNewPartTemplate() {
    return {
        "id": "",
        "model-id": "",
        "dcolor": "",
        "count": 1,
        "part-image": "",
        "cl-result-pass": "",
        "cl-result-crit": "",
        "cl-result-fail": "",
        "cl-crit-fails": undefined,
        "critical-aspect-ratio": 0.5,
        "num-detections": 3,
        "overflow-x-margin": undefined,
        "overflow-y-margin": undefined,
        "aspect-min": undefined,
        "aspect-max": undefined,
        "count": 1
    }
}

/**
 * Function generates a new empty task template based on the specifications outlined in the GitLab Wiki.
 * @returns An empty task template according to the jobFormat in GitLabWiki
 */
export function getNewTaskTemplate() {
    return {
        "id": "",
        "confirm-next": true,
        "wait": false,
        "detection-model": "",
        "detection-threshold": 0.0,
        "classification-model": "",
        "classification-threshold": 0.7,
        "text": "",
        "task-images": [
            "/src/resource/smartphone.png"
        ],
        "parts": [],
        "anti-parts": []
    }
}

/**
 * Custom function to overwrite a objects values with a passed new Object
 * 
 * Function receives either a part or task and updates the temp Object accordingly
 * It checks if the new Part/Task has a setting required by temp Task/Part.
 * If the new Part/Task provides such a setting, it overrides the temp object.
 * Else the default settings are used. 
 * 
 * @param {object} partOrTask The Task or part object passed from parent, which values overwrite the partOrTask object IF they have such a value.
 * @param {object} tempObjRef Pass temp part or task object, these values determine what can be overwritten. 
 */
export function fillTempEdited(partOrTask, tempObjRef) {
    for (var setting in tempObjRef.current) {
        if (partOrTask.hasOwnProperty(setting)) {
            tempObjRef.current[setting] = partOrTask[setting]
        }
    }
}


/**
 * This function merges the existing models as given in the availableClassificationModels states with the required
 * models as defined in the requiredModelData. The "new Set" operator is used to create a new merged list with only unique
 * values. The returned merged list represents the models as objects with a name and the existing (trained) labels.  
 * @param {*} availableClassificationModels Requires the availableClassificationModel state.
 * @param {*} requiredModelData Requires the requiredModelData state.
 * @returns A list containing objects in the form of {name: modelName, labels: [trainedLabel1, trainedLabel2, ...]}
 */
export const concatRequiredAndAvailableClassModels = (availableClassificationModels, requiredModelData) => {
    let tempAvModelList = availableClassificationModels.map(model => { return model.name })
    let tempReqModelList = requiredModelData.map(model => { return model.modelName })
    let tempMergedList = [...new Set([...tempAvModelList, ...tempReqModelList])]
    let mergedList = tempMergedList.map(tempModel => { 
        let modelInAvail = availableClassificationModels.find(model=>model.name === tempModel)
        return {"name":tempModel, 
                "labels":modelInAvail ? modelInAvail.labels : []
            }})
    return mergedList //TODO Check if there is an option to compare two arrays if they are the same
} 