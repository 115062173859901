import { useSelector } from "react-redux"

/**
 * This function returns all labels for a given workPlan
 * @param {string} workplan The workplan containing the labels 
 */
export const getRequiredLabelsForWorkplan = (workplan, requiredModelData) => {
    // Iterate over all models and concat the labels of the given workplan.
    let labelList = []
    requiredModelData.map(model => {return model})
} 

/**
 * This function returns all labels for a given model
 * @param {string} model The model of which the labels are required. 
 */
 export const getRequiredLabelsForModel = (model, requiredModelData) => {
    // Iterate over all models and concat the labels of the given model
    let labelList = []
    let modelData = requiredModelData.find(requiredModel => requiredModel.modelName === model)
    modelData?.requiredIn.map(workplan => {
        workplan.labels.length && workplan.labels.map(label => 
            {!labelList.includes(label) && labelList.push(label)}
    )})
    return labelList
} 