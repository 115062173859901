import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setTaskIndex } from '../../redux/actions'

export default function IndexIdentifier(props) {
    const { index, total, loop, prevHandler, nextHandler } = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setTaskIndex(index))
        //console.log("Index Got Updated to: ", index)
    },[index])

    return null

}
IndexIdentifier.propTypes = {
    index: PropTypes.number.isRequired,
}