import { useState, useEffect, useRef } from "react"

import { ReactComponent as EditIcon } from "../../resource/icon_edit.svg"
import { ReactComponent as SaveIcon } from "../../resource/icon_save.svg"
import {ReactComponent as ExampleJobIcon} from "../../resource/icon_examplejob.svg"


import job_icon from "../../resource/icon_job.png"

import { useDispatch, useSelector } from "react-redux";
import { DeleteComponent } from "../deleteComponent";
import { setDeletedJob, toggleDeletedJobTransfer } from "../../redux/actions";
import { replaceUmlaute } from "../../helpers/utilities";

const JobList = (props) => {
    const dispatch = useDispatch()

    // Local Storage
    const [jobList, setJobList] = useState(props.jobListData)
    const [addJobMode, setAddJobMode] = useState(false)
    const [jobIDTaken, setJobIDTaken] = useState(false)
    const [jobNameInput, setJobNameInput] = useState("New Jobname")

    useEffect(() => {
        JSON.stringify(jobList) !== JSON.stringify(props.jobListData) && setJobList(props.jobListData)
    }, [props])

    const addJob = () => {
        let jobName = jobNameInput.trim()
        let fileName = jobNameInput.trim() // option to modify filename if necessary
        // jobList.push({ "jobID": jobName, "fileName": fileName })
        props.addNewJob(jobName, fileName)
        setJobNameInput("New Jobname")
        setAddJobMode(false)

        // if (jobList.some(job => job.jobID === jobNameInput.trim())) {
        //     setJobIDTaken(true)
        // }
        // else {

        // }
    }

    function handleDeleteJob(deletedJob) {
        props.deleteJob(deletedJob)
        let tempJobList = jobList.filter(job => job.fileName !== deletedJob.fileName)
        setJobList(tempJobList)
    }

    return (
        // <div className="jobListWrapper">
        <div className="job-list scroll-body-wrapper">
            <header>
                <h1 className="job-list__title">
                    JOBLIST
                </h1>
            </header>
            <div className="job-list__profiles scroll_body">
                {jobList.map((job) => {
                    return <JobProfile job={job} selectWorkplan={props.selectWorkplan} editWorkplan={props.editWorkplan} handleDeleteJob={handleDeleteJob} />
                })}
                {!addJobMode ?
                    <article onClick={() => setAddJobMode(true)}
                        className="job-list__profile__add">
                        <p>+</p>
                    </article>
                    :
                    <article
                        className="job-list__profile">
                        <div className="job-list__profile-header">
                            <img src={job_icon}
                                alt="Job Picture" className="job-list__profile-picture" />
                            <input className="job-list__input" 
                            value={jobNameInput} 
                            onBlur={() => addJob()} 
                            onKeyDown={e => e.key === 'Enter' && addJob()} 
                            onInput={e => setJobNameInput(replaceUmlaute(e.target.value))} />
                            <span className="job-list__profile-buttons">
                                <button className="save-button nav-button" onClick={() => addJob()}>
                                    <SaveIcon/>
                                </button>
                            </span>
                        </div>

                    </article>}
            </div>

            {/* </div> */}
            {addJobMode && jobIDTaken &&
                <div className="popup-box">
                    <div className="box" onClick={() => { setJobIDTaken(false) }}>
                        JobID already taken, please choose another ID.
                    </div>
                </div>}
        </div>
    )
}

const JobProfile = (props) => {
    const [isExecutable, setIsExecutable] = useState(props.job.isExecutable)

    useEffect(() => {
        props.job.hasOwnProperty("isExecutable") && isExecutable !== props.job.isExecutable && setIsExecutable(props.job.isExecutable)
    }, [props])

    return (
        <article className="job-list__profile" onClick={() => { props.selectWorkplan(props.job.jobID) }}>
            <div className="job-list__profile-header">
                <ExampleJobIcon  class="job-list__profile-picture"/>
                <span className={`job-list__profile-name ${isExecutable ? "" : "notExecutable"}`} >{props.job.jobID}</span>
                <span className="job-list__profile-buttons">
                    <button className="edit-button nav-button" onClick={(e) => { e.stopPropagation(); props.editWorkplan(props.job.jobID) }}>
                        <EditIcon />
                    </button>
                    <DeleteComponent updateFunction={props.handleDeleteJob} deleteKey={props.job} />

                </span>
            </div>
            <hr className="rounded"></hr>
            <div className="job-list__profile-extended-info">
                <span>Extended Information: TBD</span>
            </div>
        </article>
    )
}

export default JobList
