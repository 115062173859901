import { useDispatch, useSelector } from "react-redux"
import CameraButton from "./CameraButton"
import { LabelBar } from "./LabelBar"
import { Outlet } from "react-router"
import { useEffect, useRef, useState } from "react"
import { setClassificationModel } from "../../redux/actions"
import { useSearchParams } from 'react-router-dom';


import routing from "../../routing.json"
import { StartTrainingButton } from "./StartTrainingButton"

const QuickTrainer = (props) => {
    const dispatch = useDispatch()
    const routes = routing
    let [searchParams, setSearchParams] = useSearchParams();

    const selectedLabelId = useSelector(state => state.selectedLabelId)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const classificationModel = useSelector(state => state.classificationModel)
    const labelList = useSelector(state => state.labelList)

    const [trainingPossible, setTrainingPossible] = useState(false)

    const timeout = useRef(false)

    useEffect(() => {
        if (availableClassificationModels && availableClassificationModels.length) {
            let searchParam = routes.searchParams.classModelIDParam
            let modelID = searchParams.get(searchParam)
            if (modelID && availableClassificationModels.find(model => model.name === modelID)) {
                classificationModel !== modelID && dispatch(setClassificationModel(modelID))
            } else {
                classificationModel !== "default" && dispatch(setClassificationModel("default"))
                setSearchParams({ [searchParam]: "default" })
            }
        }
    }, [availableClassificationModels])

    function hideStartTrainingButton() {
        timeout.current = true
        setTimeout(function () {
            setTrainingPossible(false);
            timeout.current = false
        }, 5000);
    }

    useEffect(() => {
        let picturesTaken = labelList.some(label => label.numClicks > 0)
        trainingPossible !== picturesTaken && !timeout.current && setTrainingPossible(picturesTaken)
    }, [labelList])

    return (
        <main 
        id="quick-trainer" 
        className="full-size-parent"
        > 
            <LabelBar />
            {selectedLabelId != -1 ? <CameraButton numClicks="0" /> : ""}
            {trainingPossible &&
                <StartTrainingButton hideStartTrainingButton={hideStartTrainingButton} />}

            <Outlet />
        </main>
    )
}

export default QuickTrainer