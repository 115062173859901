import { Button } from "react-bootstrap"
import { ReactComponent as DeleteIcon } from "../resource/icon_delete.svg"

/**
 * Standard delete Component. For GuideCard and task parts.
 * @param {function} updateFunction The update function that is invoked when the button is pressed.
 * @param {any} deleteKey The key that is passed to the the update Function. 
 * @param {boolean} hideComponent Determines if button should be visible or not. Standard: false
 * @returns 
 */
export const DeleteComponent = props => {

    const handleClick = (e) => {
        e.stopPropagation()
        props.updateFunction?.( props.deleteKey !== "undefined" ? props.deleteKey : "")
    }

    return (
        <Button className={`bs_deleteButton ${props.hideComponent ? "hideComponent" : ""}`} variant="outline-danger"
            onClick={handleClick}>
            <DeleteIcon className="delete_icon" />
        </Button>
    )
}