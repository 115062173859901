//React Components import
import React, { useState, useEffect, useRef } from 'react';
//Redux/ DataManagement import
import { channelConfig } from 'simple-peer';
import { useDispatch, useSelector } from 'react-redux';
import {
    rendered, addLabelToLabelList, setSelectedLabelId,
    toggleJobListTransfer, setJobList, requestWorkPlans, setFetchedWorkPlans
} from '../../redux/actions';
import { selectedLabelId } from '../../redux/reducers/labels';
// Own Components import
import Label from "./Label"
import { ClassModelSelector } from './ClassModelSelector';
import { AddLabelButton } from './AddLabelButton';
import { generateId } from '../../helpers/utilities';


export function LabelBar() {
    const dispatch = useDispatch()
    const [labelsChanged, setLabelsChanged] = useState(false);
    const [updateJobListFromServer, setUpdateJobListFromServer] = useState(false)

    const labelList = useSelector(state => state.labelList)
    const newJobListFromServer = useSelector(state => state.jobList)
    const newWorkplanDataFromServer = useSelector(state => state.fetchedWorkPlans)
    const requiredModelData = useSelector(state => state.requiredModelData)
    const classificationModel = useSelector(state => state.classificationModel)

    const renders = useRef(0);
    const newLabelId = useRef("")
    const labelChildRender = useRef(0);
    useEffect(() => {
        if (labelsChanged) {
            document.getElementById("label-bar__classification-labels").scrollLeft += document.getElementById("label-bar__classification-labels").scrollWidth;
            setLabelsChanged(false)

        }
        dispatch(rendered())
    });

    const addLabel = (newLabel) => {
        dispatch(addLabelToLabelList(newLabel))
        dispatch(setSelectedLabelId(newLabel.id))
        setLabelsChanged(true)
    };

    useEffect(() => {
        setUpdateJobListFromServer(true)
    }, [])

    useEffect(() => {
        if (requiredModelData && requiredModelData.length && labelList && labelList.length) {
            let requiredLabelList = []
            let modelData = requiredModelData.find(model => model.modelName === classificationModel)
            if (modelData) {
                modelData.requiredIn.map(workplan => requiredLabelList.push(...workplan.labels))
                requiredLabelList.map(reqLabel => !labelList.some(label=>label.label===reqLabel) 
                    && dispatch(addLabelToLabelList({id:generateId(), label:reqLabel })))
                console.log()
            }
        }
    }, [requiredModelData, labelList])

    useEffect(() => {
        if (updateJobListFromServer) {
            console.log("toggleJobListTransfer")
            dispatch(toggleJobListTransfer())
            setUpdateJobListFromServer(false)
        }
        if (newJobListFromServer && newJobListFromServer.length) {
            console.log("Got a new JobList from BackendServer", newJobListFromServer)
            dispatch(setJobList([]))
            const jobFiles = newJobListFromServer.map((job) => { return job.fileName })
            dispatch(requestWorkPlans(jobFiles)) // Request WorkPlan based on fileName
        }
        if (newWorkplanDataFromServer && newWorkplanDataFromServer.length) {
            console.log("Got some workplans", newWorkplanDataFromServer)
            dispatch(setFetchedWorkPlans([]))
        }
    })

    const addLabelChildRender = () => addLabelChildRender.current = addLabelChildRender.current + 1;
    return (
        <section id="label-bar">
            {console.log(`LabelBar Rendered: ${renders.current++}`)}
            {console.log(`Label Renderings: ${labelChildRender.current}`)}
            <div id="label-bar__classification-labels">
                {labelList.map((label) => (
                    <Label key={label.id} label={label} renders={addLabelChildRender} />
                ))}
            </div>
            <AddLabelButton addLabel={addLabel} />
        </section>

    );
}
