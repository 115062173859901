
// REDUCER
const totalrenderings = (state = 0, action) => {
    switch(action.type){
      case 'INCREMENT':
        return state+1;
    
    default: // need this for default case
        return state
    }
}
export default totalrenderings;