import { useEffect, useRef, useState } from 'react';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { capitalizeFirstLetter, correctInput} from '../../helpers/utilities';
import { setClassificationModel } from '../../redux/actions';
import routing from "../../routing.json";

export function ClassModelSelector(props) {
    const routes = routing

    const dispatch = useDispatch()
    const location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams();
    
    const [selectedClassModel, setSelectedClassModel] = useState("")
    const [input, setInput] = useState("")

    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const classificationModel = useSelector(state => state.classificationModel)

    const placeholder = "Add new model"

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            let newModel = correctInput(e.target.value, true)
            setInput("")
            e.target.blur()
            updateModelSelection(newModel)
        }
    }

    function updateModelSelection(model) {
        dispatch(setClassificationModel(model))
        if (location.pathname.includes(routes.quickTrainer)) {
            let searchParam = routes.searchParams.classModelIDParam
            setSearchParams({ [searchParam]: model })
        }
    }

    return (
        <DropdownButton
            id="class-model-selector"
            variant="secondary"
            size="sm"
            menuVariant="dark"
            title={classificationModel !== "" ? classificationModel : "Select Classification Model"}>
            <Form.Control
                className="bs_standardInputField"
                type="text"
                placeholder={placeholder}
                value={input}
                onInput={(e) => setInput(correctInput(e.target.value, false))}
                onKeyDown={handleEnter} />
            <Dropdown.Divider />
            {availableClassificationModels.map(model => {
                return (
                    <Dropdown.Item
                        size="sm"
                        onClick={() => updateModelSelection(model.name)}>
                        {model.name}
                    </Dropdown.Item>
                )
            })}
        </DropdownButton>
    );
}

