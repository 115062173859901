//React Components import
import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
//Redux/ DataManagement import
// Third party Components import
import 'bootstrap';
import { DropdownButton, Dropdown, Form, Button, FloatingLabel, CloseButton } from 'react-bootstrap';

// Helpers import
import { capitalizeFirstLetter, replaceUmlaute } from "../../../helpers/utilities";
import { getNewPartTemplate } from "../../../helpers/jobHelpers";
import { setClassificationModel } from "../../../redux/actions";

export const AddPartDropDownButton = (props) => {
    const [labelSelected, setLabelSelected] = useState(false)
    const [labelList, setLabelList] = useState([])

    const selectedClassModel = useRef(props.selectedClassModel)
    const selectedLabelId = useRef("")

    useEffect(() => {
        if (props.selectedClassModel !== "" && props.selectedClassModel !== selectedClassModel.current) {
            props.parentLabelList && setLabelList(props.parentLabelList.current)
        }
    }, [props])

    useEffect(() => {
        if (labelSelected) {
            var newPart = getNewPartTemplate()
            newPart["id"] = selectedLabelId.current
            newPart["model-id"] = selectedLabelId.current
            newPart["cl-result-pass"] = selectedLabelId.current
            props.updateTask("parts", { "value": newPart, "partIndex": -1 })
            setLabelSelected(false)
        }
    })

    return (
        <div className="add-check-button" style={{zIndex: props.zIndex}}>
            <DropdownButton 
                bsPrefix="add-check-button__button" 
                disabled={!props.editable}
                onClick={() => props.toggleZIndex("addLabel")}
                title="+"
                size="sm"
                menuVariant="dark"
                align="end" 
                drop="down"
                flip={true}
                >
            <AddPartDropDown
                updatePartList={props.updateParentLabelList}
                labelList={labelList}
                selectedLabelId={selectedLabelId}
                setLabelSelected={setLabelSelected} />
        </DropdownButton>
        </div >
    )
}

export const AddPartDropDown = (props) => {
    const [input, setInput] = useState("")
    const placeholder = "Add new label"

    return (
        <>
            <Form.Control
                className="bs_standardInputField"
                type="text"
                placeholder={placeholder}
                value={input}
                onInput={(e) => setInput(replaceUmlaute(e.target.value.toLowerCase()))}
                onKeyDown={e => e.key === 'Enter' &&
                    (props.updatePartList(input), props.selectedLabelId.current = input,
                        setInput(""), props.setLabelSelected(true), e.target.blur())
                } />
            <Dropdown.Divider />
            {props.labelList.map(label => {
                return (
                    <Dropdown.Item size="sm" onClick={() => { props.selectedLabelId.current = label; props.setLabelSelected(true) }}>
                        {capitalizeFirstLetter(label)}
                    </Dropdown.Item>
                )
            })}
        </>
    )
}


export const ClassificationModelDropdown = (props) => {
    const dispatch = useDispatch()

    const [selectedClassModel, setSelectedClassModel] = useState("")
    const [input, setInput] = useState("")
    const placeholder = "Add new model"

    const classModels = useRef([])
    const sthChanged = useRef(false)

    useEffect(() => {
        classModels.current = props.classModels
        props.modelInCurrentWorkplan && props.modelInCurrentWorkplan !== "" && setSelectedClassModel(props.modelInCurrentWorkplan)
    }, [props])

    useEffect(() => {
        if (selectedClassModel !== "" && sthChanged.current) {
            props.updateTask("classification-model", selectedClassModel)
            sthChanged.current = false
        }
    }, [selectedClassModel])

    const updateModelList = (newModelName) => {
        console.log(newModelName)
        // selectedLabelId.current = newPartName
        if (!classModels.current.includes(newModelName)) {
            //classModels.current.unshift(newModelName)
            dispatch(setClassificationModel(newModelName))
        }
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            let newModel = input.replace(/ /g, '')
            updateModelList(newModel)
            setInput("")
            e.target.blur()
            sthChanged.current = true; setSelectedClassModel(newModel)
        }
    }

    return (
        <DropdownButton
            bsPrefix={props.name && props.name}
            disabled={!props.editable}
            onClick={() => props.toggleZIndex("selectModel")}
            variant="secondary"
            size="sm"
            menuVariant="dark"
            title={selectedClassModel !== "" ? capitalizeFirstLetter(selectedClassModel) : "Select Classification Model"}>
            <Form.Control
                className="bs_standardInputField"
                type="text"
                placeholder={placeholder}
                value={input}
                onInput={(e) => setInput(replaceUmlaute(e.target.value.toLowerCase()))}
                onKeyDown={handleEnter} />
            <Dropdown.Divider />
            {classModels.current.map(model => {
                return (
                    <Dropdown.Item size="sm" onClick={() => { sthChanged.current = true; setSelectedClassModel(model) }}>
                        {capitalizeFirstLetter(model)}
                    </Dropdown.Item>
                )
            })}
        </DropdownButton>


    );
}


/**
 * Generic context Input that passes the input on Enter to the Parent Component.
 * setOpen = Function that opens or closes the Context Input.
 * updateParent = Passes Input value to this function.
 */
export const ContextInput = (props) => {
    const [input, setInput] = useState("")

    return (
        <FloatingLabel
            controlId="floatingInput"
            label="New Label Name"
            className="mb-3"
            id="bs_contextInput"
        >
            <CloseButton id="bs_xButton" onClick={() => { props.setOpen(false) }} />
            <Form.Control
                type="text"
                placeholder="New Label Name"
                value={input}
                onInput={(e) => setInput(replaceUmlaute(e.target.value))}
                onKeyDown={e => e.key === 'Enter' && (props.updateParent(input), props.setOpen(false))} />

        </FloatingLabel>
    )
}