import Peer from 'simple-peer'

export default class simplePeer {
    peer = null
    track= null 
    init = (stream, initiator) => {
        if (!stream) {
            stream = null
        }
        this.peer = new Peer({
            initiator: initiator,
            stream: stream,
            channelConfig:{maxRetransmits:0,ordered:false},
            channelName:"datachannel",
            trickle: false,
            reconnectTimer: 1000,
            iceTransportPolicy: 'relay',
            config: {
                iceServers: []
            },
            objectMode:true,
        })
        this.track=stream.getVideoTracks()[0]
        console.log("PeerTrackFROMPEERPERSPECTIVE",stream.getVideoTracks())
        console.log(this.track)
        // return this.peer,this.track
    }
    getPeer =()=>{
        return this.peer
    }
    getTrack =()=>{
        return this.track
    }
    connect = (otherId) => {
        this.peer.signal(otherId)
    } 
    send = (data) =>{
        this.peer.send(data)
    }
    disconnect = () =>{
        this.peer.destroy()
    }
    replaceTrack = (newTrack) =>{
        console.log("SimplePeerMyPeer:",this.peer)
        console.log("SimplePeerMyTrack:",this.track)
        console.log("OldTrack in Peer",this.peer.track)
        console.log("NewPeerThatWasSet",newTrack)
        console.log("Stream from track perspective",this.peer.stream)
        this.peer.replaceTrack(this.track,newTrack,this.peer.stream)
    }
    addTrack = (track) =>{
        this.peer.addTrack(track)
    }
    removeTrack = (oldTrack) =>{
        this.peer.removeTrack(oldTrack)
    }
    addStream = (stream) =>{
        this.peer.addStream(stream);
    }
    removeStream= (stream) => {
    /** Remove a `MediaStream` from the connection. */
        this.peer.removeStream(stream);
    }
    
} 
