import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleStartTraining } from "../../redux/actions"

export function StartTrainingButton(props) {
    const dispatch = useDispatch()
    const trainingFinished = useSelector(state => state.trainingFinished)
    const trainingState = useRef("not_started")
    const startedTraining = useSelector(state => state.trainingStarted)
    const [text, setText] = useState("Start Training")
    const [icon, setIcon] = useState("🚀")


    useEffect(() => {
        if (trainingFinished && trainingState.current === "started" ) {
            trainingState.current = "finished"
            setText("Training finished")
            setIcon("✅")
            props.hideStartTrainingButton()
        }
        else if (!trainingFinished && trainingState.current === "not_started") {
            trainingState.current = "started"
        }
    }, [trainingFinished])


    function startTraining() {
        if (trainingFinished && trainingState.current === "not_started") {
            setText("Training is running")
            setIcon("⏳")
            dispatch(toggleStartTraining())
        }
    }

    return (
        <button
            id="start-training-button"
            type="button"
            onClick={() => startTraining()}>
            <span className={trainingFinished ? "" : "rotating"}>
                {icon}
            </span>
            {text}
        </button>
    )
}