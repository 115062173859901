export const rendered = () => {
    return {
        type: 'INCREMENT'
    }
}

export const setCurrentLabel = (label) => {
    return {
        type: 'NEW_LABEL',
        payload: label
    }
}

export const addLabelToLabelList = (label) => {
    return {
        type: 'ADD_LABEL',
        payload: label
    }
}

export const removeLabelFromLabelList = (label) => {
    return {
        type: 'REMOVE_LABEL',
        payload: label
    }
}

export const setLabelList = (labelList) => {
    return {
        type: 'SET_LABELLIST',
        payload: labelList
    }
}

export const toggleScrollToLabel = () => {
    return {
        type: 'CHANGE_SCROLLTOLABEL_MODE',
    }
}
export const changeLabelName = (labelId, newLabelName) => {
    return {
        type: 'RENAME_LABEL',
        payload: {
            "newLabelName": newLabelName,
            "labelId": labelId
        }
    }

}

export const changeRemoteName = (labelId, newLabelName) => {
    return {
        type: 'RENAME_REMOTE_LABEL',
        payload: {
            "newLabelName": newLabelName,
            "labelId": labelId
        }
    }

}

export const setSelectedLabelId = (labelId) => {
    return {
        type: 'SELECTED_LABEL_BY_ID',
        payload: labelId
    }
}

export const toggletakeImg = () => {
    return {
        type: 'TAKE_IMG'
    }
}
export const toggleStartTraining = () => {
    return {
        type: 'START_TRAINING'
    }
}
export const setTrainingFinished = (finished) => {
    return {
        type: 'TRAINING_FINISHED',
        payload: finished
    }
}

export const toggleStartTrainingOnDeselect = () => {
    return {
        type: "CHANGE_TRAINSTART_MODE"
    }
}
export const toggleDrawBoundingBoxMode = () => {
    return {
        type: "CHANGE_DRAWBOUNDINGBOX_MODE"
    }
}
export const setBoundingBoxPosition = (boundingBoxPosition) => {
    return {
        type: "NEW_BOUNDINGBOXPOSITION",
        payload: boundingBoxPosition
    }
}

export const setDetectionBoundingBoxPositions = (detectionResults) => {
    return {
        type: "NEW_BOUNDING_BOXES",
        payload: detectionResults
    }
}
export const setRemoveLabelFromServer = (label) => {
    return {
        type: "REMOVE_LABEL_FROM_SERVER",
        payload: label
    }
}
export const sethighlightThreshold_classification = (threshold) => {
    return {
        type: "NEW_CLASSIFICATION_THRESHOLD",
        payload: threshold
    }
}
export const sethighlightThreshold_detection = (threshold) => {
    return {
        type: "NEW_DETECTION_THRESHOLD",
        payload: threshold
    }
}
export const setClassificationModel = (modelName) => {
    return {
        type: 'SET_CLASSIFICATION_MODEL',
        payload: modelName
    }
}
export const setRemoveClassModelOnServer = (modelName) => {
    return {
        type: 'REMOVE_CLASSIFICATION_MODEL_FROM_SERVER',
        payload: modelName
    }
}
export const setDetectionModel = (modelName) => {
    return {
        type: 'SET_DETECION_MODEL',
        payload: modelName
    }
}
export const setAvailableDetectionModels = (modelNames) => {
    return {
        type: 'NEW_AVAILABLE_DETECTION_MODELS',
        payload: modelNames
    }
}
export const setAvailableClassificationModels = (modelNames) => {
    return {
        type: 'NEW_AVAILABLE_CLASSIFICATION_MODELS',
        payload: modelNames
    }
}

export const setNumTrainSteps = (steps) => {
    return {
        type: "NEW_NUMBER_OF_TRAINING_STEPS",
        payload: steps
    }
}

export const setUserName = (name) => {
    return {
        type: "SET_USERNAME",
        payload: name
    }
}

export const setTaskIndex = (index) => {
    return {
        type: "SET_TASK_INDEX",
        payload: index
    }
}

export const setTempJob = (job) => {
    return {
        type: "SET_TEMP_JOB",
        payload: job
    }
}

export const updateJobName = (jobID) => {
    console.log("jobID: ", jobID)
    return {
        type: "UPDATE_JOB_NAME",
        payload: jobID
    }
}

export const updateTaskName = (taskName, taskIndex) => {
    console.log("taskName: ", taskName)
    return {
        type: "UPDATE_TASK_NAME",
        payload: taskName,
        index: taskIndex
    }
}

export const updateTaskDescription = (taskDesc, taskIndex) => {
    console.log("taskDesc: ", taskDesc)
    return {
        type: "UPDATE_TASK_DESCRIPTION",
        payload: taskDesc,
        index: taskIndex
    }
}

export const setAnswerOnSave = (answer) => {
    return {
        type: "SET_ANSWER_ON_SAVE",
        payload: answer
    }
}

export const toggleJobListTransfer = () => {
    return {
        type: "TOGGLE_JOBLIST_TRANSFER"
    }
}

export const setJobList = (jobList) => {
    return {
        type: "SET_JOBLIST",
        payload: jobList
    }
}

export const requestWorkPlans = (jobFileNames) => {
    return {
        type: "GET_WORK_PLANS",
        payload: jobFileNames
    }
}

export const setFetchedWorkPlans = (workPlans) => {
    return {
        type: "SET_FETCHED_WORK_PLANS",
        payload: workPlans
    }
}

export const setNumClicks = (labelId) => {
    return {
        type: "INCREMENT_NUM_CLICKS",
        payload: labelId
    }
}

export const resetNumClicks = () => {
    return {
        type: "RESET_NUM_CLICKS",
    }
}

export const toggleEditedJobTransfer = () => {
    return {
        type: "TOGGLE_EDITED_JOB_TRANSFER"
    }
}

export const toggleDeletedJobTransfer = () => {
    return {
        type: "TOGGLE_DELETED_JOB_TRANSFER"
    }
}

export const setEditedJob = (editedJob) => {
    return {
        type: "SET_EDITED_JOB",
        payload: editedJob
    }
}

export const setDeletedJob = (deletedJob) => {
    return {
        type: "SET_DELETED_JOB",
        payload: deletedJob
    }
}

export const initRequiredModelData = (requiredModelData) => {
    return {
        type: "INIT_REQUIRED_MODEL_DATA",
        payload: requiredModelData
    }
}

export const addPartToRequiredModelData = (newReqPartData) => {
    return {
        type: "ADD_PART_TO_REQUIRED_MODEL_DATA",
        payload: newReqPartData
    }
}

export const addClassModelToRequiredModelData = (newReqClassModelData) => {
    return {
        type: "ADD_CLASS_MODEL_TO_REQUIRED_MODEL_DATA",
        payload: newReqClassModelData
    }
}

export const addWorkplanToRequiredModelData = (newReqWorkplanData) => {
    return {
        type: "ADD_WORKPLAN_TO_REQUIRED_MODEL_DATA",
        payload: newReqWorkplanData
    }
}

export const setConnectionStatus = (status) => {
    return {
        type: "SET_CONNECTION_STATUS",
        payload: status
    }
}

export const setTrainingState = (status) => {
    return {
        type: "SET_TRAINING_STATE",
        payload: status
    }
}

export const setActiveCamera = (activeCamera) => {
    return {
        type: "SET_ACTIVE_CAMERA",
        payload: activeCamera
    }
}
export const setAvailableCameraDevices = (availableCameraDevices) => {
    return {
        type: "SET_AVAILABLE_CAMERA_DEVICES",
        payload: availableCameraDevices
    }
}
export const setCameraFacingMode = (facingMode)=>{
    return {
        type: "SET_CAMERA_FACING_MODE",
        payload: facingMode
    }
}



