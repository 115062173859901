//React Components import
import { useState, useRef, useEffect } from "react"

// Third party Components import
import Carousel from 're-carousel'
import IndicatorDots from "../../helpers/GuideCardHelper/indicator-dots"
import IndexIdentifier from "../../helpers/GuideCardHelper/indexIdentifier"

import { GuideCard } from "./GuideCard";

function GuideCardSlider(props) {
    const [jobID, setJobID] = useState("")

    const taskList = useRef([])
    const guideCardVideo = useRef()

    useEffect(() => {
        guideCardVideo.current.srcObject = props.videoStream;
        guideCardVideo.current.setAttribute("playsinline", true);
    }, [])

    useEffect(() => {
        setJobID(props.workplanData.id)
        taskList.current = props.workplanData.tasks
        console.log(props.workplanData)
        console.log("Entered GuideCard Modus")
        checkVideo()
    }, [props])

    function checkVideo() {
        try {
            guideCardVideo.current.srcObject !== props.videoStream && (guideCardVideo.current.srcObject = props.videoStream);
            !guideCardVideo.current.playsInline && guideCardVideo.current.setAttribute("playsinline", true);
        } catch (err) {
            alert("ERROR: ", err)
        }
    }

    return (
        <div id="guide-card-slider" className="scroll-body-wrapper">
            <header id="guide-card-slider__header">
                <button
                    className="back-button"
                    title="Go To JobList"
                    onClick={() => { props.setWorkplanToExecute({}); props.setWorkplanToEdit({}) }}>
                    ᐊ
                </button>
                <h1>
                    {jobID}
                </h1>
            </header>
            <div id="guide-card-slider__body" className="center">
                <video
                    autoPlay
                    id='guide-card-slider__video'
                    muted
                    ref={guideCardVideo}
                >
                </video>
                <div id="guide-card-slider__carousel" className="full-size-parent">
                    {taskList.current.length ?
                        <Carousel id="carousel" widgets={[IndicatorDots, IndexIdentifier]} >
                            {taskList.current.map((task, taskIndex) => {
                                // console.log("EL&Index: ", element, ";", listIndex);
                                return (
                                    <GuideCard key={task.id} taskIndex={taskIndex} task={task} jobName={jobID} />
                                )
                            })}
                        </Carousel> :
                        <div id="guide-card-slider__carousel-placeholder">
                            <h1> This Job has no Tasks. </h1>
                        </div>}
                </div>
            </div>

        </div>
    )
}


export default GuideCardSlider