import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as KKLogoMaennchen } from '../../resource/KK_Logo_Maennchen.svg';

export default function ConnectionStatus(props) {
    const [status, setStatus] = useState()
    const connectionStatus = useSelector(state => state.connectionStatus)

    const modification = useRef("")

    useEffect(() => {
        switch (connectionStatus) {
            case "not-connected":
                setStatus("--not-connected")
                break
            case "connecting":
                setStatus("--connecting")
                break
            case "connected":
                setStatus("--connected")
                break
            default:
        }
    }, [connectionStatus])

    return (
        <div className={`connection-status${status}`}>
            <div className="connection-status__logo">
                <KKLogoMaennchen />
            </div>
            <div className="connection-status__text">
                <span>
                    {connectionStatus}
                </span>
            </div>

        </div>
    )
}