import { useRef, useState, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import VideoPlaceholder from './components/VideoPlaceholder';
import JobAssistant from './components/JobAssistant/JobAssistant';
import QuickTrainer from './components/QuickTrainer/QuickTrainer';
import routing from "./routing.json"
import StartPage from './components/StartPage';
import HomePage from './components/HomePage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAvailableCameraDevices
} from './redux/actions';

export default function App() {
  const [videoStream, setVideoStream] = useState()
  const routes = routing
  const [localUserName, setLocalUserName] = useState("")
    const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const dispatch = useDispatch()

  useEffect(() => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
      } else {
        // List cameras and microphones.
        let cameraDevices=[]
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              device.kind=='videoinput'&&cameraDevices.push(device)&&console.log(`MediaDevices:${device.kind}: ${device.label} id = ${device.deviceId}`);
            });
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          });
          dispatch(setAvailableCameraDevices(cameraDevices))
          console.log(cameraDevices)
      }
    }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<StartPage />} >
          <Route
            path=":userName"
            element={
              <VideoPlaceholder
                setVideoStream={setVideoStream}
              />}>
            <Route path={routes.homePage} element={<HomePage />} />
            <Route path={routes.quickTrainer} element={<QuickTrainer videoStream={videoStream} />}>
            </Route>
            <Route path={routes.jobAssistant} element={<JobAssistant videoStream={videoStream} />} >
            </Route>
          </Route>
        </ Route>
      </Routes>
    </BrowserRouter>
  )
            }
