import {ReactComponent as ExampleTaskIcon} from "../../resource/icon_exampletask.svg"

//React Components import
import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';

//Redux/ DataManagement import
import {
    setClassificationModel
} from "../../redux/actions"

import { Badge } from "react-bootstrap";
// Own Components import
import { CheckButtonExecutable } from './ownComponents/PartsButtons';

// Helpers import
import { capitalizeFirstLetter } from "../../helpers/utilities";

// GuideGard represents one task of the task list.
export const GuideCard = (props) => {
    const dispatch = useDispatch()

    const currentClassModel = useSelector(state => state.classificationModel)
    const currentTaskIndex = useSelector(state => state.taskIndex)
    const classificationThreshold = useSelector(state => state.highlightThreshold_classification)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)

    const [classModel, setClassModel] = useState("")
    const [numPartsChecked, setNumPartsChecked] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState()
    const [isExecutable, setIsExecutable] = useState(true)

    const taskIndex = useRef(props.taskIndex)
    const task = useRef(props.task)
    const parts = useRef(props.task.parts)

    const renders = useRef(0)
    const numPartsToCheck = useRef(0)
    const givenClassThreshold = useRef()

    useEffect(() => {
        console.log("KEY VALUE OF GUIDECARD", JSON.stringify(props.key))

        taskIndex.current = props.taskIndex
        task.current = props.task
        parts.current = props.task.parts
        setClassModel(props.task["classification-model"])
        numPartsToCheck.current = props.task.parts.length
        if (task.current.hasOwnProperty("classification-threshold") && task.current["classification-threshold"] >= 0 && task.current["classification-threshold"] <= 1) {
            givenClassThreshold.current = task.current["classification-threshold"]
        }
    }, [props])

    useEffect(() => {
        if (isExecutable && numPartsToCheck.current === numPartsChecked && backgroundColor !== "rgba(60,200,60,0.6) !important") {
            console.log("All Parts checked")
            setBackgroundColor("rgba(60,200,60,0.6)");
        }
        if (taskIndex.current === currentTaskIndex && currentClassModel !== classModel) {
            console.log(`Changed to ${taskIndex}`)
            if (classModel) {
                const modelName = classModel
                if (availableClassificationModels.some(model => model.name === modelName)) {
                    console.log(`Change CL Model "${currentClassModel}" to "${classModel}" of task "${taskIndex}"" with index: "${currentTaskIndex}"`)
                    dispatch(setClassificationModel(modelName))
                }
            }

        }
        console.log(numPartsChecked, "/", numPartsToCheck.current, "Parts checked")
    })

    const partHasBeenChecked = () => {
        setNumPartsChecked(numPartsChecked + 1)
    }

    return (
        <div
            className="guide-card"
            style={{ backgroundColor: backgroundColor }}
        >
            {console.log(`GuideCard ${taskIndex} rendered: ${renders.current++}`)}
            <div className="guide-card__job-title">
                <h1>
                    {props.jobName}
                </h1>
            </div>

            <div className="guide-card__task-title">
                <span>{props.task.id}</span>
            </div>

            <h6 id="badge-wrapper">
                {!isExecutable && <Badge bg="danger">
                    Task is not executable: {<br />} Classification Model "{classModel}" {<br />} has missing labels
                </Badge>}
            </h6>
            <div className="guide-card__content">
                <div className="guide-card__check-values">
                    {parts.current.map((part) => {
                        return (
                            <CheckButtonExecutable
                                key={part.id}
                                taskIndex={props.taskIndex}
                                part={part}
                                onClick={() => { }}
                                givenClassThreshold={givenClassThreshold.current}
                                setNumPartsChecked={partHasBeenChecked}
                                classModel={classModel}
                                setIsExecutable={setIsExecutable}
                            />)
                    }
                    )}
                </div>
                <div className="guide-card__info">
                    <div className="guide-card__task-description">
                        <span>{task.current.text ? props.task.text : "No description"}</span>
                    </div>
                    <ExampleTaskIcon />
                </div>
            </div>
        </div>
    )
}