import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router";
import { rendered, setUserName } from "../redux/actions";
import routing from "../routing.json"
import { HomeButton } from "./homeButton";
import { ClassModelSelector } from './QuickTrainer/ClassModelSelector';

export default function StartPage() {
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const routes = routing

    const [localUserName, setLocalUserName] = useState("")
    const [isHomePage, setIsHomePage] = useState(false)
    const [isQuickTrainer, setIsQuickTrainer] = useState(false)
    const connectedToPeer = useRef(true) // Use something like this when videostream is getting established on startpage
    const renders = useRef(0);

    useEffect(() => {
        var userName = params.userName
        userName && handleUserName(userName)
    }, [])

    useEffect(() => {
        dispatch(rendered())
    })

    useEffect(() => {
        let isOnHomePage = location.pathname === "/" + localUserName + "/" + routes.homePage
        let isonStartPage = location.pathname === "/"
        setIsHomePage(isOnHomePage || isonStartPage)
        let isOnQuickTrainer = location.pathname.includes(routes.quickTrainer)
        setIsQuickTrainer(isOnQuickTrainer)
    }, [location])

    function handleUserName(userName) {
        if (userName) {
            setLocalUserName(userName)
            dispatch(setUserName(userName))
        }
    }

    return (
        <main id="start-page">
            <div id="start-page__background-image">
                <div id="start-page__background-overlay">
                    {console.log(`App Rendered: ${renders.current++}`)}
                    {!localUserName && <h1>No UserName set - Please log in to connect to KimoknowDetectionServer</h1>}
                </div>
            </div>
            <div id="start-page__sub-menu">
                {isQuickTrainer && <ClassModelSelector />}
                {!isHomePage && <HomeButton userName={localUserName} />}
            </div>
            <Outlet />
        </main>
    );
}
