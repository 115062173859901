import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useLocation, useMatch, useNavigate, useParams } from "react-router"
import { setUserName } from "../redux/actions"
import { NavMenu } from "./navMenu"
import VideoFeed from "./videoFeed"
import routing from "../routing.json"
import HomePage from "./HomePage"

export default function VideoPlaceholder(props) {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const params = useParams()
    var location = useLocation()

    const [localUserName, setLocalUserName] = useState("")
    const [hideVideoFeed, setHideVideoFeed] = useState(false)
    const [hideText, setHideText] = useState(true)
    // const hideVideoFeed = useRef(false)

    const routes = routing
    useEffect(() => {
        var userName = params.userName
        userName && handleUserName(userName)
    }, [])

    useEffect(() => {
        location.pathname.includes(routing.jobAssistant) ? setHideVideoFeed(true) : setHideVideoFeed(false)
        if (location.pathname === `/${params.userName}` || location.pathname === `/${params.userName}/`) {
            navigate(`/${params.userName}/${routes.homePage}`)
        }
    }, [location])

    function handleUserName(userName) {
        if (userName) {
            setLocalUserName(userName)
        }
    }

    return (
        <main id="video-placeholder" className="full-size-parent">
            <section id="video-placeholder__video-section"
                className={`${hideVideoFeed ? "visually-hidden" : ""}`} >
                <VideoFeed
                    setVideoStream={props.setVideoStream}
                    userName={localUserName}
                />
                <h1 id="text" className={`${hideText ? "visually-hidden" : ""}`}>
                    Add "quicktrainer" or "jobassistant" to the URL</h1>
            </section>
            <Outlet />
        </main >
    )
}