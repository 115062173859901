//Style import
import { CSSTransition } from 'react-transition-group';
//React Components import
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import { AddPartDropDown } from './OwnDropDownMenus';
import { capitalizeFirstLetter, replaceUmlaute } from '../../../helpers/utilities';
import { DeleteComponent } from '../../deleteComponent';
// Own Components import

const InputField = props => {
  const [input, setInput] = useState("")
  const placeholder = useRef("")
  const valueToChange = useRef("")
  // const partLabel = useRef("")

  useEffect(() => {
    // partLabel.current = capitalizeFirstLetter(props.partLabel)
    placeholder.current = `Edit ${props.keyToChange}`
    valueToChange.current = props.valueToChange
    setInput(capitalizeFirstLetter(props.valueToChange))
  }, [props])

  return (
    <Form.Control
      className="bs_inputInNavmenu"
      type="text"
      placeholder={placeholder}
      value={input}
      onFocus={() => setInput(valueToChange.current)}
      onInput={e => setInput(replaceUmlaute(e.target.value))}
      onKeyDown={e => e.key === 'Enter' && (props.updatePart(props.keyToChange, input), e.target.blur())}
      onBlur={() => { props.updatePart(props.keyToChange, input.toLowerCase()); setInput(valueToChange.current) }}
    />
  )
}

const LabelSelectorList = (parentLabelList, keyToChange, valueToChange, updatePart, updatePartList) => {
  const [labelList, setLabelList] = useState([])
  const checkMarkOn = "✓"

  useEffect(() => {
    if (parentLabelList.length) {
      let tempLabelList = parentLabelList.map(label => {
        return ({
          title: capitalizeFirstLetter(label),
          leftIcon: capitalizeFirstLetter(label).charAt(0),
          rightIcon: valueToChange == label ? checkMarkOn : "",
          customAction: () => updatePart(keyToChange, label.toLowerCase())
        })
      })
      let partInput = {
        title: "addPart",
        showComponentInsteadOfTitle: true,
        component: <PartInput parentLabelList={parentLabelList} updatePartList={updatePartList} keyToChange={keyToChange} updatePart={updatePart} />
      }
      tempLabelList.unshift(partInput)
      setLabelList(tempLabelList)
    }
  }, [parentLabelList, valueToChange])

  return labelList
}

const PartInput = (props) => {
  const [addPartInput, setAddPartInput] = useState("")
  const addPartPlaceholder = "Add new part"

  return (
    <Form.Control
      id="bs_popUp_addPart"
      type="text"
      placeholder={addPartPlaceholder}
      value={addPartInput}
      onInput={(e) => setAddPartInput(replaceUmlaute(e.target.value.toLowerCase()))}
      onKeyDown={e => e.key === 'Enter'
        && !props.parentLabelList.some(label => label === addPartInput)
        && (props.updatePartList(addPartInput), setAddPartInput(""), props.updatePart(props.keyToChange, addPartInput), e.target.blur())}
    />
  )
}

export function PopUpMenu(props) {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const [childLabelList, setChildLabelList] = useState([])

  const parentSize = useRef();
  const windowSize = useRef();
  const dropdownRef = useRef(null);
  const relXPosition = useRef(0)
  const selectedClassModel = useRef(props.selectedClassModel)
  const sthChanged = useRef(false)

  const sliderValueNumDetection = useRef(props.part["num-detections"])
  const [sliderValueClassThreshold, setSliderValueClassThreshold] = useState(0.5)
  const rightArrow = "➔"

  var mainMenuElements = [
    {
      title: "Model-ID",
      showComponentInsteadOfTitle: true,
      rightIcon: <DeleteComponent deleteKey={"delete-part"} updateFunction={props.updatePart} />,
      component: <InputField keyToChange={"model-id"} valueToChange={props.part["model-id"]} partLabel={props.part["cl-result-pass"]} updatePart={props.updatePart} />
    },
    {
      title: "Pass Label",
      id: "cl-result-pass",
      leftIcon: "P",
      rightIcon: rightArrow,
      showComponentInsteadOfTitle: false,
      subMenu: LabelSelectorList(childLabelList, "cl-result-pass", props.part["cl-result-pass"], props.updatePart, updateChildLabelList)// Return must be a list of LabelSelectors
    },
    {
      title: "Crit Label",
      id: "cl-result-crit",
      leftIcon: "C",
      rightIcon: rightArrow,
      showComponentInsteadOfTitle: false,
      subMenu: LabelSelectorList(childLabelList, "cl-result-crit", props.part["cl-result-crit"], props.updatePart, updateChildLabelList)// Return must be a list of LabelSelectors
    },
    {
      title: "Fail Label",
      id: "cl-result-fail",
      leftIcon: "F",
      rightIcon: rightArrow,
      showComponentInsteadOfTitle: false,
      subMenu: LabelSelectorList(childLabelList, "cl-result-fail", props.part["cl-result-fail"], props.updatePart, updateChildLabelList)// Return must be a list of LabelSelectors
    },
    {
      title: "Sliders",
      leftIcon: "📊",
      showComponentInsteadOfTitle: false,
      rightIcon: rightArrow,
      subMenu: [{
        leftIcon: "n-Det",
        rightIcon: sliderValueNumDetection.current,
        title: "num-detections",
        showComponentInsteadOfTitle: true,
        component: <Form.Range
          id="bs_thresholdSlider"
          value={sliderValueNumDetection.current}
          max={50} min={0} step={1}
          onChange={(event) => { sliderValueNumDetection.current = event.target.value; sthChanged.current = true }}
          onPointerUp={() => { sthChanged.current && (props.updatePart("num-detections", sliderValueNumDetection.current) && (sthChanged.current = false)) }}
        />
      },
      {
        leftIcon: "Cl-Th",
        rightIcon: sliderValueClassThreshold,
        title: "classification-threshold",
        showComponentInsteadOfTitle: true,
        component: <Form.Range
          id="bs_thresholdSlider"
          value={sliderValueClassThreshold}
          max={1} min={0} step={0.01}
          onChange={(event) => { setSliderValueClassThreshold(event.target.value); sthChanged.current = true }}
          onPointerUp={() => { sthChanged.current && (props.updatePart("classification-threshold", sliderValueClassThreshold) && (sthChanged.current = false)) }}
        />
      }
      ]
    }
  ]

  useEffect(() => {
    const parentBox = document.getElementById(props.parentElement).getBoundingClientRect();
    parentSize.current = parentBox
    calcTranslateXValue()
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    document.getElementById("popUp_nav").focus()
    if (props.selectedClassModel !== "") {
      selectedClassModel.current = props.selectedClassModel
    }
  }, [])

  useEffect(() => {
    props.part["num-detections"] !== sliderValueNumDetection.current && (sliderValueNumDetection.current = parseInt(props.part["num-detections"]))
    setChildLabelList(props.parentLabelList.current)
    props.classThreshold !== sliderValueClassThreshold && setSliderValueClassThreshold(props.classThreshold)
  }, [props])

  var calcTranslateXValue = () => {
    const popUpMenuBox = dropdownRef.current.getBoundingClientRect()
    const parent = parentSize.current
    const buttonPosition = props.openButton.getBoundingClientRect();
    const relativeButtonPositon = Math.round(((buttonPosition.left + (buttonPosition.right - buttonPosition.left)) - parent.left) / parent.width * 100)
    relXPosition.current = relativeButtonPositon > 50 ? -(relativeButtonPositon - 50) : (50 - relativeButtonPositon)
    console.log("Calculated the navMenuPosition:", relXPosition)
    var newLeft = (popUpMenuBox.left - popUpMenuBox.width * (relXPosition.current / 100)) - parent.left
    relXPosition.current = newLeft < 0 ? ((parent.left - popUpMenuBox.left) / popUpMenuBox.width) * 100 : relXPosition.current
    console.log("NewLeftPositionReleativ to GuideCard:", newLeft)
    newLeft = (popUpMenuBox.left - popUpMenuBox.width * (relXPosition.current / 100)) - parent.left
    console.log("Correted newLeftValue", newLeft)
    // console.log(rect2)
    // const xmin = position.x / parentSize.current.width;
    // const ymin = position.y / parentSize.current.height;
    // const xmax = (position.x + rect2.width) / parentSize.current.width;
    // const ymax = (position.y + rect2.height) / parentSize.current.height;

    // console.log(xmin,ymin,xmax,ymax)
    // var dict = { xmin: xmin >= 0 ? xmin : 0, ymin: ymin >= 0 ? ymin : 0, xmax: xmax <= 1 ? xmax : 1, ymax: ymax <= 1 ? ymax : 1 }
    // relativeCoords.current = dict

  }
  function updateChildLabelList(newPartName) {
    setChildLabelList([newPartName, ...childLabelList])
    props.updateParentLabelList(newPartName)
  }

  function handleResize() {
    if (document.getElementById(props.parentElement)) {
      const parentBox = document.getElementById(props.parentElement).getBoundingClientRect();
      parentSize.current = parentBox
    }
  }

  window.addEventListener('resize', handleResize)

  function calcHeight(el) {
    const height = el.offsetHeight + 5;
    setMenuHeight(height);
  }

  // useEffect(() => {
  //   let height = dropdownRef.current.firstChild.offsetHeight
  //   setMenuHeight(height);
  // })

  function DropdownItem(props) {
    return (
      <span
        className="menu-item"
        onClick={() => props.customAction ? props.customAction() : (props.goToMenu && setActiveMenu(props.goToMenu))}>
        {props.leftIcon && <div className={`icon-button ${props.id ? props.id : ""}`}>{props.leftIcon}</div>}
        {props.children}
        {props.rightIcon && <span className={`icon-right ${props.goToMenu ? "expandable" : ""}`}>{props.rightIcon}</span>}
      </span>
    );
  }

  return (
    <div tabIndex="1" className="popUp_nav" id="popUp_nav" style={{ height: menuHeight, transform: `translateX(${relXPosition.current}%) translateY(123%)` }} ref={dropdownRef}
    // onBlur={(e) => {alert(e.target.tagName) ;if (e.target.tagName !=="DIV" && e.target.tagName !=="INPUT") {return} else {props.setDropDownOpen(false)} }}
    >
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
        onEnter={calcHeight}>
        <div>
          {mainMenuElements.map((popUpElement) => {
            return (
              <div>
                <DropdownItem key={popUpElement.title} id={popUpElement.id} goToMenu={popUpElement.showComponentInsteadOfTitle ? false : popUpElement.title} leftIcon={popUpElement.leftIcon} rightIcon={popUpElement.rightIcon}>
                  <span className="title">
                    {(popUpElement.showComponentInsteadOfTitle && popUpElement.component) ? popUpElement.component : popUpElement.title}
                  </span>
                </DropdownItem>
              </div>
            )
          })
          }
        </div>
      </CSSTransition>
      {mainMenuElements.map((popUpElement) => {
        return (
          <CSSTransition
            in={activeMenu === popUpElement.title}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}>
            <div className="menu">
              <DropdownItem goToMenu="main" leftIcon="⇦">
                <h2>Main</h2>
              </DropdownItem>
              {popUpElement.subMenu && popUpElement.subMenu.map((subMenuItem) => {
                return (
                  <DropdownItem key={subMenuItem.title} goToMenu={subMenuItem.showComponentInsteadOfTitle ? false : popUpElement.title} leftIcon={subMenuItem.leftIcon} rightIcon={subMenuItem.rightIcon} customAction={subMenuItem.customAction}>
                    <span className="title">
                      {(subMenuItem.showComponentInsteadOfTitle && subMenuItem.component) ? subMenuItem.component : subMenuItem.title}
                    </span>
                  </DropdownItem>)
              })}
            </div>
          </CSSTransition>
        )
      })
      }
    </div>

  );
}
