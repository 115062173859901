export const jobList = (state = [], action) => {
    switch (action.type) {
        case 'SET_JOBLIST':
            return action.payload;
        default: // need this for default case
            return state
    }
}

export const fetchedWorkPlans = (state = [], action) => {
    switch (action.type) {
        case 'SET_FETCHED_WORK_PLANS':
            return action.payload;
        default: // need this for default case
            return state
    }
}

export const taskIndex = (state = -1, action) => {
    switch (action.type) {
        case "SET_TASK_INDEX":
            return action.payload;
        default:
            return state
    }
}

export const answerOnSave = (state = {}, action) => {
    switch (action.type) {
        case "SET_ANSWER_ON_SAVE":
            return action.payload
        default:
            return state
    }
}

export const requestJobListFromServer = (state = false, action) => {
    switch (action.type) {
        case 'TOGGLE_JOBLIST_TRANSFER':
            return !state;
        default:
            return state;
    }
}

export const requestedWorkplansFromServer = (state = [], action) => {
    switch (action.type) {
        case 'GET_WORK_PLANS':
            return action.payload;
        default:
            return state;
    }
}

export const sendEditedJobToServer = (state = false, action) => {
    switch (action.type) {
        case 'TOGGLE_EDITED_JOB_TRANSFER':
            return !state;
        default:
            return state;
    }
}

export const editedJob = (state = {}, action) => {
    switch (action.type) {
        case "SET_EDITED_JOB":
            return action.payload;
        default:
            return state;
    }
}

export const requiredModelData = (state = [], action) => {
    switch (action.type) {
        case "INIT_REQUIRED_MODEL_DATA":
            return action.payload;
        case "ADD_PART_TO_REQUIRED_MODEL_DATA":
            return state.map((model) => model.modelName === action.payload.classModel ?
                { ...model, "requiredIn": model.requiredIn.map(workplan => workplan.workplanName === action.payload.jobName ? 
                    { ...workplan, "labels": Array.isArray(action.payload.part) ? workplan.labels.concat(action.payload.part) : [...workplan.labels, action.payload.part] } 
                    : workplan) }
                : model)
        case "ADD_CLASS_MODEL_TO_REQUIRED_MODEL_DATA":
            return [...state, { "modelName": action.payload.classModel, "requiredIn": [] }]
        case "ADD_WORKPLAN_TO_REQUIRED_MODEL_DATA":
            return state.map((model) => model.modelName === action.payload.classModel ?
                {
                    ...model, "requiredIn": [...model.requiredIn, { "workplanName": action.payload.jobName, "labels": [] }
                    ]
                } : model)
        default:
            return state;
    }
}

export const deletedJob = (state = {}, action) => {
    switch (action.type) {
        case "SET_DELETED_JOB":
            return action.payload;
        default:
            return state;
    }
}

export const sendDeletedJobToServer = (state = false, action) => {
    switch (action.type) {
        case 'TOGGLE_DELETED_JOB_TRANSFER':
            return !state;
        default:
            return state;
    }
}