//Style import
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheets/main.scss'
//React Components import
import React from 'react';
import ReactDOM from 'react-dom';
//Redux/ DataManagement import
import { Provider } from 'react-redux';
import reportWebVitals from './helpers/reportWebVitals';
//import * as butler from './serviceWorker';
import { createStore } from 'redux';
import allReducers from "./redux/reducers";
//Own Components import
// import App from './App';
import App from './App';

//DATA STORE
const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);
//butler.unregister()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
