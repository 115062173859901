import { combineReducers } from 'redux';
import totalrenderings from './counter';

import {
    currentLabel, labelList, scrollToLabel, selectedLabelId,
    drawBoundingBox, boundingBoxPosition, highlightThreshold_classification,
    highlightThreshold_detection, detectionBoundingBoxPositions,
    availableDetecionModels, availableClassificationModels, numTrainSteps,
    userName
} from './labels';
import {
    takeImg, startTraining, trainingFinished, startTrainingOnDeselect,
    removeLabelOnServer, detectionModel, classificationModel, removeClassModelOnServer,
    renameRemoteLabel, trainingState
} from './peerCommands';
import {
    jobList, taskIndex,
    requestedWorkplansFromServer, fetchedWorkPlans
    , answerOnSave, requestJobListFromServer, sendEditedJobToServer, editedJob,
    requiredModelData, sendDeletedJobToServer, deletedJob, 
} from "./jobs"
import { connectionStatus,selectedCamera,availableCameraDevices,cameraFacingMode } from './general';

const allReducers = combineReducers({
    totalrenderings,
    currentLabel,
    labelList,
    scrollToLabel,
    selectedLabelId,
    takeImg,
    startTraining,
    trainingFinished,
    startTrainingOnDeselect,
    drawBoundingBox,
    boundingBoxPosition,
    detectionBoundingBoxPositions,
    removeLabelOnServer,
    highlightThreshold_classification,
    highlightThreshold_detection,
    detectionModel,
    classificationModel,
    availableClassificationModels,
    availableDetecionModels,
    numTrainSteps,
    userName,
    jobList,
    taskIndex,
    requestedWorkplansFromServer,
    fetchedWorkPlans,
    answerOnSave,
    requestJobListFromServer,
    sendEditedJobToServer,
    editedJob,
    requiredModelData,
    removeClassModelOnServer,
    deletedJob,
    sendDeletedJobToServer,
    connectionStatus,
    renameRemoteLabel,
    trainingState,
    selectedCamera,
    availableCameraDevices,
    cameraFacingMode

})

export default allReducers;
