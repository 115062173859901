import {ReactComponent as ExampleTaskIcon} from "../../resource/icon_exampletask.svg"

//React Components import
import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';

//Redux/ DataManagement import
import {
    addPartToRequiredModelData, addClassModelToRequiredModelData, addWorkplanToRequiredModelData
} from "../../redux/actions"

// Own Components import
import { CheckButtonForEditMode } from './ownComponents/PartsButtons';
import { AddPartDropDownButton, ClassificationModelDropdown } from './ownComponents/OwnDropDownMenus';

// Helpers import
import { fillTempEdited, getNewTaskTemplate } from "../../helpers/jobHelpers";
import { capitalizeFirstLetter, replaceUmlaute } from "../../helpers/utilities";
import { getRequiredLabelsForModel, getRequiredLabelsForWorkplan } from "../../helpers/requiredModelStateHelpers";
import { DeleteComponent } from "../deleteComponent";

//Third Party
import { Badge, Form, Col } from "react-bootstrap";
import InputField from "../../helpers/InputField";

export const TaskEditable = (props) => {
    const dispatch = useDispatch()

    // Needed redux states
    // const currentTaskIndex = useSelector(state => state.taskIndex)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    const requiredModelData = useSelector(state => state.requiredModelData)
    // Needed local states
    const [partAdded, setPartAdded] = useState(false)
    const [jobName, setJobName] = useState("")
    const [backgroundColor, setBackgroundColor] = useState("rgba(20, 20, 20,0.8)")

    const [checkValuesZIndex, setCheckValuesZIndex] = useState()
    const [modelWrapperZIndex, setModelWrapperZIndex] = useState()
    
    const [checkButtonZIndex, setCheckButtonZIndex] = useState()
    const [addCheckButtonZIndex, setAddCheckButtonZIndex] = useState()

    // Needed refs
    const taskIndex = useRef()
    const isNewTask = useRef(false)
    const renders = useRef(0)
    const task = useRef({})
    const taskListLength = useRef()
    const sthChanged = useRef(false)
    const taskEdited = useRef(getNewTaskTemplate())
    const parentLabelList = useRef([])
    const parentModelList = useRef([])

    useEffect(() => {
        setJobName(props.jobName)
        task.current = props.task
        taskIndex.current = props.taskIndex
        if (task.current.id && task.current.id !== "") {
            fillTempEdited(task.current, taskEdited) // Custom reusable function, see on Bottom of file
            console.log("Setup EditableGuideCard")

            // Setup label list with available and required labels.
            initParentLabelList()

            // Set available Model list
            parentModelList.current = availableClassificationModels.map(model => { return model.name })
        } else {
            console.log("Received a task without an ID")
        }
    }, [])

    useEffect(() => {
        taskIndex.current = props.taskIndex
        taskListLength.current = props.taskListLength
        props.taskListLength - 1 === props.taskIndex ? (isNewTask.current = true) : (isNewTask.current = false)
    }, [props])

    /**
     * Function that triggers manually the check if something has changed and consequently triggers the 
     * updateWorkplan function at the level of the Jobassistant. 
     */
    const triggerUpdateWorkPlan = () => {
        // if (currentTaskIndex === taskIndex.current && taskIsActive.current === true) {
        console.log("Update Triggered directly")
        taskIndex.current + 1 === taskListLength.current && console.log("New Task Created")
        checkSthChanged()
        // }
    }

    /** Function that checks if sth has changed in the current workplan.
    * To update workplan the task needs to have an id. 
    */
    const checkSthChanged = () => {
        if (sthChanged.current && taskEdited.current.id !== "") {
            props.updateWorkplan(task.current.id, taskEdited.current)
            console.log("New workplan information pushed to GuideCardSlider")
            if (partAdded) {
                setPartAdded(false)
            }
        }
        sthChanged.current = false
    }
    /**
     * Function updates the requiredModel data, either for a newly added part or model.
     * @param {string} key Can be either "parts" or "models"
     * @param {*} value Can be either the new partData or the new classModel name
     */
    const updateRequiredModelData = (key, value) => {
        switch (key) {
            case ("parts"):
                const currentClassModel = taskEdited.current["classification-model"]
                let labelCheckList = [value.id]
                value["cl-result-crit"]?.length && labelCheckList.push(value["cl-result-crit"])
                value["cl-result-fail"]?.length && labelCheckList.push(value["cl-result-fail"])
                labelCheckList.forEach(value => {
                    let tempObject = { "classModel": currentClassModel, "jobName": jobName, "part": value }
                    let modelInReqModelData = requiredModelData.find(modelData => modelData.modelName === currentClassModel)
                    let workplanInReqModelData = modelInReqModelData.requiredIn.find(workplan => workplan.workplanName === jobName)
                    if (!workplanInReqModelData) {
                        dispatch(addWorkplanToRequiredModelData(tempObject))
                        dispatch(addPartToRequiredModelData(tempObject))
                    } else {
                        !workplanInReqModelData.labels.includes(value) && dispatch(addPartToRequiredModelData(tempObject))
                    }
                })
                break
            case ("classification-model"):
                let tempObjectCM = { "classModel": value, "jobName": jobName, "part": taskEdited.current.parts.map(part => part.id) }
                let modelInReqModelDataCM = requiredModelData.find(modelData => modelData.modelName === value)
                if (!modelInReqModelDataCM) { // Model not in reqModels
                    dispatch(addClassModelToRequiredModelData(tempObjectCM))
                    dispatch(addWorkplanToRequiredModelData(tempObjectCM))
                    dispatch(addPartToRequiredModelData(tempObjectCM))
                }
                else { //Model already in reqModels
                    if (!modelInReqModelDataCM.requiredIn.some(workplan => workplan.workplanName === jobName)) { // workplan not in RequiredIn
                        dispatch(addWorkplanToRequiredModelData(tempObjectCM)) // Workplan not in requiredIn
                        dispatch(addPartToRequiredModelData(tempObjectCM))
                    }
                    else { // Workplan in requiredIn
                        let labelsReqIn = modelInReqModelDataCM.requiredIn.find(workplan => workplan.workplanName === jobName).labels
                        tempObjectCM["part"] = tempObjectCM["part"].filter(label => !labelsReqIn.includes(label))
                        dispatch(addPartToRequiredModelData(tempObjectCM))
                    }
                }
                break
        }

    }
    /**
     * This function is updating the keyToChange of the current task with the given value.   
     * @param {string} keyToChange The key of the JSON data of the current task. 
     * @param {*} value The new value of the current task at the keyToChange.
     */
    const updateTask = (keyToChange, value) => {
        const oldValue = taskEdited.current.hasOwnProperty(keyToChange) ? taskEdited.current[keyToChange] : ""
        switch (keyToChange) {
            case ("id"):
                taskEdited.current[keyToChange] = value
                if (value !== "") sthChanged.current = true
                triggerUpdateWorkPlan()
                break
            case ("text"):
                taskEdited.current[keyToChange] = value
                sthChanged.current = true
                triggerUpdateWorkPlan()
                break
            case ("parts"):
                if (value.partIndex === -1) {
                    taskEdited.current[keyToChange].push(value.value)
                    setPartAdded(true) // Trigger render
                } else {
                    taskEdited.current[keyToChange][value.partIndex] = value.value
                }
                sthChanged.current = true
                triggerUpdateWorkPlan()
                updateRequiredModelData(keyToChange, value.value)
                break
            case ("classification-model"):
                if (value !== "") {
                    taskEdited.current[keyToChange] = value // Hardcoded
                    sthChanged.current = true
                    let tempLabelList = availableClassificationModels.find(model => model.name === value)
                    tempLabelList && (parentLabelList.current = tempLabelList.labels)
                    triggerUpdateWorkPlan() // Trigger update directly if new classModel set
                    updateRequiredModelData(keyToChange, value)
                    initParentLabelList()
                }
                break
            case ("classification-threshold"):
                if (value >= 0 && value <= 1) {
                    taskEdited.current[keyToChange] = value
                    sthChanged.current = true
                    triggerUpdateWorkPlan()
                }
                break
            case ("delete-part"):
                taskEdited.current["parts"] = taskEdited.current["parts"].filter((part, index) => index !== value)
                sthChanged.current = true
                triggerUpdateWorkPlan()
        }
        console.log(`Edited ${keyToChange}:${oldValue} -> ${taskEdited.current[keyToChange]}`)
    }
    /**
     * This function checks if the given newLabelName is already in the parentLabelList of the TaskEditable. 
     * If it is not included it will be added to the List.
     * @param {string} newLabelName The name of the new label, which has to be added.
     */
    const updateParentLabelList = (newLabelName) => {
        !parentLabelList.current.includes(newLabelName) && parentLabelList.current.unshift(newLabelName)
    }
    const updateParentModelList = (newModelName) => {
        !parentModelList.current.includes(newModelName) && parentLabelList.current.unshift(newModelName)
    }
    /**
     * This function initializes the parentLabelList. It therefore combines the available labels of the model taken out 
     * of the availableClassificationModel state and the list with the required labels as given in the 
     * requiredModelData state. The "new Set" operator is used to create a new list containing only the unique values.
     */
    const initParentLabelList = () => {
        if (taskEdited.current["classification-model"]?.length) {
            let existingLabels = availableClassificationModels.find(model => model.name === taskEdited.current["classification-model"])?.labels
            let tempLabelList = existingLabels ? existingLabels : []
            let requiredLabels = getRequiredLabelsForModel(taskEdited.current["classification-model"], requiredModelData)
            parentLabelList.current = [...new Set([...tempLabelList, ...requiredLabels])]
        }
    }
    /**
     * This function is needed to deal with the different z-indices of the different dropdown menus
     * and check buttons. Since they can not be set in the scss files directly, the active component has
     * to receive the highest z-score directly.
     * @param {string} component 
     */
    function toggleZIndex(component) {
        switch (component) {
            case "addLabel": {
                setModelWrapperZIndex(10)
                setCheckValuesZIndex(20)
                setAddCheckButtonZIndex(25)
                setCheckButtonZIndex(22)
                break
            }
            case "selectModel": {
                setModelWrapperZIndex(20)
                setCheckValuesZIndex(10)
                break
            }
            case "openPopup": {
                setModelWrapperZIndex(10)
                setCheckValuesZIndex(20)
                setAddCheckButtonZIndex(22)
                setCheckButtonZIndex(25)
                break
            }
        }
        console.log("toggleZIndex fired by: ", component)
    }
    return (
        <div className="task-editable" >
            {console.log(`Task ${taskIndex.current} rendered: ${renders.current++}`)}
            <div className="task-editable__header">
                <InputField name="task-id" type="input" keyToChange={"id"} valueToChange={taskEdited.current.id} updateFunction={updateTask} triggerFunction={triggerUpdateWorkPlan} />
                <DeleteComponent updateFunction={props.deleteTaskByIndex} deleteKey={taskIndex.current} hideComponent={taskIndex.current === taskListLength.current - 1} />
            </div>
            <div className="task-editable__info">
                <InputField name="task-text" label="Task-Description" type="textarea" keyToChange={"text"} valueToChange={taskEdited.current.text} updateFunction={updateTask} triggerFunction={triggerUpdateWorkPlan} />
                <ExampleTaskIcon />
            </div>
            <div className="task-editable__model-wrapper" style={{zIndex: modelWrapperZIndex}} >
                <ClassificationModelDropdown
                    name="model-dropdown"
                    modelInCurrentWorkplan={taskEdited.current["classification-model"]}
                    classModels={availableClassificationModels.map(model => { return model.name })}
                    updateTask={updateTask}
                    toggleZIndex={toggleZIndex}
                    editable={!isNewTask.current && taskEdited.current.id !== ""} />
            </div>
            <div className="task-editable__check-values" style={{zIndex: checkValuesZIndex}}>
                {taskEdited.current.parts && taskEdited.current.parts.map((part, index) => {
                    return (
                        <CheckButtonForEditMode
                            updateTask={updateTask}
                            selectedClassModel={taskEdited.current["classification-model"]}
                            classThreshold={taskEdited.current["classification-threshold"]}
                            key={part.id}
                            partIndex={index}
                            part={part}
                            zIndex={checkButtonZIndex}
                            toggleZIndex={toggleZIndex}
                            updateParentLabelList={updateParentLabelList}
                            parentLabelList={parentLabelList}
                            onClick={() => { }}
                        />)
                })}
                <AddPartDropDownButton
                    updateTask={updateTask}
                    zIndex={addCheckButtonZIndex}
                    toggleZIndex={toggleZIndex}
                    selectedClassModel={taskEdited.current["classification-model"]}
                    updateParentLabelList={updateParentLabelList}
                    parentLabelList={parentLabelList}
                    editable={taskEdited.current.id !== "" && taskEdited.current["classification-model"] !== ""}
                />
            </div>
        </div>)
}


const TaskContentForm = props => {
    return (
        <Form horizontal>
            <TaskContentInputField
                keyToChange={"id"}
                valueToChange={props.taskId}
                updateTask={props.updateTask}
                triggerUpdateWorkPlan={props.triggerUpdateWorkPlan} />
            <TaskContentInputField
                keyToChange={"text"}
                valueToChange={props.taskText}
                updateTask={props.updateTask}
                triggerUpdateWorkPlan={props.triggerUpdateWorkPlan} />
        </ Form>)
}

const TaskContentInputField = props => {
    const [input, setInput] = useState("")
    const placeholder = useRef("")
    const valueToChange = useRef("")

    useEffect(() => {
        placeholder.current = `Edit Task-${capitalizeFirstLetter(props.keyToChange)}`
        valueToChange.current = props.valueToChange
        setInput(props.valueToChange ? props.valueToChange : "")
    }, [props])

    return (
        <Form.Group >
            <Form.Label sm="2">{capitalizeFirstLetter(props.keyToChange)}</Form.Label>
            <Col sm={10} >
                <Form.Control
                    className="bs_inputInGuideCard"
                    type="text"
                    placeholder={placeholder.current}
                    value={input}
                    onBlur={() => props.triggerUpdateWorkPlan()}
                    onFocus={() => setInput(valueToChange.current)}
                    onInput={e => setInput(replaceUmlaute(e.target.value))}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            props.updateTask(props.keyToChange, input)
                            props.triggerUpdateWorkPlan && props.triggerUpdateWorkPlan()
                            e.target.blur()
                        }
                    }
                    }
                />
            </ Col>
        </ Form.Group >
    )
}