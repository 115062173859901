
import { Button } from 'react-bootstrap';

let nextId = 0;
export function generateId() {
  const result = nextId;
  nextId += 1;
  return result;
}
export const getTimeStamp = () => {
  var date = new Date(Date.now())
  var year = date.getFullYear().toString()
  var month = (date.getMonth() + 1).toString()
  var day = date.getDate().toString()
  var hour = date.getHours()
  var minutes = date.getMinutes().toString()
  var seconds = date.getSeconds().toString()

  month = month.length === 1 ? `0${month}` : month
  day = day.length === 1 ? `0${day}` : day
  hour = hour.length === 1 ? `0${hour}` : hour
  minutes = minutes.length === 1 ? `0${minutes}` : minutes
  seconds = seconds.length === 1 ? `0${seconds}` : minutes
  return year + month + day + hour + minutes + seconds;
}
/**
 * Custom function that capitalizes the first character of a string* 
 * @param {string} string String of which the firs char is going to be capitalized.
 * */
export function capitalizeFirstLetter(string) {
  if (string && string !=="") return string.charAt(0).toUpperCase() + string.slice(1);
  else return string
}

/**
 * Function that removes the path in the classification model key of the workplan data. 
 * @param {Array} newWorkPlanData Workplan Data send from backend  
 * @returns {Array} Workplan with only modelnames
 */
export const extractModelName = (newWorkPlanData) => {
  let workplanDataWithModelName = newWorkPlanData.map((workplan) => {
    return ({
      ...workplan, "tasks": workplan.tasks.map(task => {
        return ((task.hasOwnProperty("classification-model") && task["classification-model"]) ?
          { ...task, "classification-model": task["classification-model"].split(/[\./]/)[task["classification-model"].split(/[\./]/).length - 2] }
          : task
        )
      })
    })
  })
  return workplanDataWithModelName
}

/**
 * Initializing requiredModelData based on the workplan data received from the backend
 * @param {Array} newWorkPlanData Workplan Data send from backend  
 * @returns {Array} tempReqModelData, ready to be set into redux state
 */
export const setupRequiredModelData = (newWorkPlanData) => {

  function getLabels(task) {
    let tempPartList = []
    // Check doubles
    task.parts?.map(part => {
      let passLabel = part.id
      let critLabel = part["cl-result-crit"]
      let failLabel = part["cl-result-fail"]
      !tempPartList.includes(passLabel) && tempPartList.push(part.id)
      critLabel.length && !tempPartList.includes(critLabel) && tempPartList.push(critLabel)
      failLabel.length && !tempPartList.includes(failLabel) && tempPartList.push(failLabel)
    })
    return tempPartList
  }

  let tempReqModelData = []
  newWorkPlanData?.forEach((workplan) => {
    workplan.tasks?.forEach((task) => {
      // Check if model already in temReqModelData
      if (task.hasOwnProperty("classification-model") && task["classification-model"] && task["classification-model"] !== "") {
        let classModelName = task["classification-model"]
        let classModelInReqModel = tempReqModelData.some((model) => model.modelName === classModelName)
        if (!tempReqModelData.length || !classModelInReqModel) {
          // Initialize new required Model Object
          let tempPartList = getLabels(task)
          let tempObject = {
            "modelName": classModelName,
            "requiredIn": [{
              "workplanName": workplan.id,
              "labels": tempPartList
            }]
          }
          tempReqModelData.push(tempObject)
        } else if (classModelInReqModel) {
          // Handle updating existing Object
          let existingReqModelData = tempReqModelData.find((model) => model.modelName === classModelName)

          // Check if current Workplan ID is already part of requiredIn
          if (existingReqModelData.requiredIn.some(reqWorkplan => reqWorkplan.workplanName === workplan.id)) {
            // WorkplanId already in requiredIn  
            let reqWorkplanObj = existingReqModelData.requiredIn.find(reqWorkplan => reqWorkplan.workplanName === workplan.id)
            task.parts.map(part => {
              !reqWorkplanObj.labels.includes(part.id) && reqWorkplanObj.labels.push(part.id)
            })
          } else {
            let tempPartList = getLabels(task)
            let tempObject = {
              "workplanName": workplan.id,
              "labels": tempPartList
            }
            tempReqModelData.map(tempModel => tempModel.modelName === existingReqModelData.modelName && tempModel.requiredIn.push(tempObject))
          }

        }
      }
    })
  })
  return tempReqModelData
}

/**
 * CURRENTLY ONLY CLASSMODEL
 * This function adds suffix ".kcmodel" and ".kmodel" to class model and detection model in the workplan. 
 * @param {*} workplanData The Data where the suffix has to be added
 * @returns workplanData with added suffix
 */
export const addPathsToWorkplan = (workplanData) => {
  const tempArray = workplanData.map((workplan) => {
    return ({
      ...workplan, "tasks": workplan.tasks.map(task => {
        return ((task.hasOwnProperty("classification-model") && task["classification-model"]) ?
          { ...task, "classification-model": task["classification-model"] + ".kcmodel" }
          : task
        )
      })
    }
    )
  })
  return tempArray

}

/**
 * This function replaces the German Umlaute (ä, ö, ü and ß) with (ae, oe, ue and ss).
 * @param {string} str 
 * @returns 
 */
export function replaceUmlaute(str) {

  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  }

  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
      (a) => umlautMap[a]
    );
}

const test = ['Übung', 'ÜBUNG', 'üben', 'einüben', 'EINÜBEN', 'Öde ätzende scheiß Übung']
test.forEach((str) => console.log(str + " -> " + replaceUmlaute(str)))


/**
 * Helper function that lowers the input string and replaces the german umlaute. If wanted the
 * trailing whitespaces are removed aswell. 
 * 
 * @param {str} str The string that should be checked and corrected. 
 * @param {bool} trim If trailing whitespaces should be removed.
 * @returns The corrected string
 */
export function correctInput(str, trim) {
  let correctedString = replaceUmlaute(str.toLowerCase())
  let char_regex = /[.,?!#*"//\/]/g;
  correctedString = correctedString.replace(char_regex, '')  
  if (trim) {
    correctedString = correctedString.trim().replace(/ +(?= )/g,'')
  }
  return correctedString
}