import job_icon from "../../resource/icon_job.png"
//React Components import
import { useState, useRef, useEffect } from "react"
//Redux/ DataManagement import

// Third party Components import
import 'bootstrap';

// Own Components import
import { TaskEditable } from './TaskEditable';
// Helpers import

function EditJobMode(props) {
    const [jobID, setJobID] = useState("")
    const [fileName, setFileName] = useState("")
    const [taskList, setTaskList] = useState([])

    const sthChanged = useRef(false)
    const newTask = useRef(false)
    const taskListLength = useRef(0)

    useEffect(() => {
        setJobID(props.workplanData.id)
        let temp = [...props.workplanData.tasks]
        if (temp.length === 0 || Object.keys(temp[temp.length - 1]).length !== 0) { // check if last element is empty
            temp.push({})
        }
        taskListLength.current = temp.length
        setTaskList(temp)
        // setTaskList(props.workplanData.tasks)
        console.log(props.workplanData)
        console.log("Entered Edit Mode")
    }, [props])

    useEffect(() => {
        console.log("TaskList has been updated")
        console.log(taskList)
    }, [taskList])

    const deleteTaskByIndex = (taskIndex) => {
        let changedTaskList = taskList.filter((task, index) => index !== taskIndex)
        triggerWorkplanDataUpdate(changedTaskList)
    }

    const updateWorkplan = (oldTaskId, task) => {
        newTask.current = false // reset
        console.log("Saving and Updating Workplan where id:", oldTaskId, "->", task)
        const indexToChange = taskList.findIndex(obj => obj.id === oldTaskId);
        if (indexToChange === taskList.length - 1) newTask.current = true // Check if new task has been added
        console.log("changing index", indexToChange)
        let changedTaskList = [...taskList]
        changedTaskList[indexToChange] = task
        console.log(changedTaskList)
        triggerWorkplanDataUpdate(changedTaskList)
    }

    const triggerWorkplanDataUpdate = (changedTaskList) => {
        // Delete empty element on the end of the list
        if (Object.keys(changedTaskList[changedTaskList.length - 1]).length === 0) {
            changedTaskList.pop()
        }
        props.updateWorkplanData(jobID, changedTaskList)

        // Add empty element on the end of the list; not the best design, but necessary due to carousel
        let temp = [...changedTaskList]
        temp.push({})
        taskListLength.current = temp.length
        setTaskList(temp)
    }

    return (
        <div id="edit-mode" className="scroll-body-wrapper">
            <header id="edit-mode__header">
                <button
                    className="back-button"
                    title="Go To JobList"
                    onClick={() => { props.setWorkplanToExecute({}); props.setWorkplanToEdit({}) }}>
                    ᐊ
                </button>
                <h1>
                    {jobID}
                </h1>
            </header>
            <div id="edit-mode__body" className="scroll_body">
                {taskList.map((task, taskIndex) => {
                    // console.log("EL&Index: ", element, ";", listIndex);
                    return (
                        <TaskEditable
                            key={task.id}
                            taskIndex={taskIndex}
                            task={task} jobName={jobID}
                            updateWorkplan={updateWorkplan}
                            taskListLength={taskListLength.current}
                            deleteTaskByIndex={deleteTaskByIndex}
                        />
                    )
                })}
            </div>
        </div>
    )
}

// GuideGard represents one task of the task list.

export default EditJobMode