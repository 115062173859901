//React Components import
import { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
// Helpers import
import { fillTempEdited, getNewPartTemplate } from "../../../helpers/jobHelpers";
import { DropDownLabelList } from "./OwnDropDownMenus";
// Third party Components import

// Own Components import
import { PopUpMenu } from "./popUpMenu";

// CheckButton represents one part in the task
export const CheckButtonForEditMode = (props) => {
    const [partIndex, setPartIndex] = useState()
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const settingsButtonRef = useRef(null);
    const sthChanged = useRef(false)
    const partEdited = useRef(getNewPartTemplate())

    useState(() => {
        setPartIndex(props.partIndex) // Trigger a render
        fillTempEdited(props.part, partEdited)
    }, [])
    // initialize state to encounter unwanted missing values
    useEffect(() => {
        setPartIndex(props.partIndex)
    }, [props])

    const checkSthChanged = () => {
        if (sthChanged.current && partEdited.current.id && partEdited.current.id !== "") {
            props.updateTask("parts", { "value": partEdited.current, "partIndex": partIndex })
            console.log("New part information pushed to GuideCardSlider")
        }
        sthChanged.current = false
    }
    const updatePart = (keyToChange, value) => {
        const oldValue = partEdited.current.hasOwnProperty(keyToChange) ? partEdited.current[keyToChange] : ""
        switch (keyToChange) {
            case ("id"):
                break
            case ("model-id"):
                partEdited.current[keyToChange] = value
                oldValue !== value && (sthChanged.current = true)
                break
            case ("part-image"):
                break
            case ("cl-result-pass"):
                partEdited.current[keyToChange] = value
                sthChanged.current = true
                break
            case ("cl-result-crit"):
                partEdited.current[keyToChange] = value
                sthChanged.current = true
                break
            case ("cl-result-fail"):
                partEdited.current[keyToChange] = value
                sthChanged.current = true
                break
            case ("cl-crit-fails"):
                partEdited.current[keyToChange] = value
                sthChanged.current = true
                break
            case ("num-detections"):
                partEdited.current[keyToChange] = parseInt(value)
                sthChanged.current = true
                break
            case ("classification-threshold"):
                props.updateTask(keyToChange, value)
                break
            case ("delete-part"):
                props.updateTask(keyToChange, partIndex)
        }
        checkSthChanged()
        console.log(`Edited ${keyToChange}:${oldValue} -> ${partEdited.current[keyToChange]}`)
    }

    return (
        <div className="check-button" style={{zIndex: props.zIndex}}>
            <button
                className={`check-button__button ${dropdownOpen ? "popUpOpened" : ""}`}
                ref={settingsButtonRef}
                onClick={() => { setDropDownOpen(!dropdownOpen); props.toggleZIndex("openPopup") }}
            > {partEdited.current["model-id"]}

            </button>
            {dropdownOpen && <PopUpMenu
                part={partEdited.current}
                classThreshold={props.classThreshold}
                updatePart={updatePart}
                openButton={settingsButtonRef.current}
                parentElement="edit-mode"
                selectedClassModel={props.selectedClassModel}
                setDropDownOpen={setDropDownOpen}
                updateParentLabelList={props.updateParentLabelList}
                parentLabelList={props.parentLabelList}
            />}
        </div>
    )
}

// CheckButton represents one part in the task
export const CheckButtonExecutable = (props) => {
    // Read out necessary states from redux
    const cl_label = useSelector(state => state.currentLabel)
    const currentTaskIndex = useSelector(state => state.taskIndex)
    const globalClassificationThreshold = useSelector(state => state.highlightThreshold_classification)
    const availableClassificationModels = useSelector(state => state.availableClassificationModels)
    // taskIndex makes part identifiable as part of the respective task 
    const [taskIndex, setTaskIndex] = useState(props.taskIndex)

    // Read out properties of task in json
    const [checkValue, setCheckValue] = useState(props.part["cl-result-pass"])
    const [criticalValue, setCriticalValue] = useState(props.part["cl-result-crit"])
    const [notOk, setNotOk] = useState(props.part["cl-result-fail"])

    // Refs
    const id = useRef(props.part.id)
    const modelId = useRef(props.part["model-id"])
    const partCount = useRef(props.part.count)
    const num_detections = useRef(3)

    // Setup Counts for the check Values
    const checkCount = useRef(0);
    const critCount = useRef(0);
    const notOKCount = useRef(0);
    const classificationThreshold = useRef(0.7)
    const labelIsTrained = useRef(true)

    const [checked, setChecked] = useState(false)
    const backgroundcolor = useRef("rgba(0, 0, 0, 0.7)")

    const renders = useRef(0)

    // initialize state to encounter unwanted missing values
    useEffect(() => {
        setTaskIndex(props.taskIndex)
        setCheckValue(props.part["cl-result-pass"])
        setCriticalValue(props.part["cl-result-crit"])
        setNotOk(props.part["cl-result-fail"])
        props.part["num-detections"] && (num_detections.current = parseInt(props.part["num-detections"]))
        if (props.givenClassThreshold) {
            classificationThreshold.current = props.givenClassThreshold
        } else {
            classificationThreshold.current = globalClassificationThreshold
        }
        if (props.classModel && props.classModel !== "" && availableClassificationModels && availableClassificationModels.length) {
            let trainedLabels = availableClassificationModels.find(model => model.name === props.classModel.toLowerCase())
            trainedLabels && trainedLabels.labels.includes(props.part.id.toLowerCase())
                ? labelIsTrained.current = true
                : labelIsTrained.current = false
        }
        !labelIsTrained.current && props.setIsExecutable(false)
    }, [props])

    useEffect(() => {
        if (currentTaskIndex === taskIndex) {
            if (!checked) {
                if (checkValue === cl_label.label && cl_label.conf >= classificationThreshold.current) {
                    checkCount.current++;
                    console.log(checkCount.current, "/", num_detections.current, "to check label", checkValue)
                    backgroundcolor.current = "rgba(0,255,0,0.6)";
                    if (checkCount.current === num_detections.current) {
                        setChecked(true)
                        props.setNumPartsChecked()
                        console.log(checkValue, "has been checked")
                    }
                }
                else if (criticalValue === cl_label.label && cl_label.conf >= classificationThreshold.current) {
                    critCount.current++
                    // if (critCount === num_detections)
                    backgroundcolor.current = "rgba(236, 215, 31, 0.979)";
                }
                else if (notOk === cl_label.label && cl_label.conf >= classificationThreshold.current) {
                    notOKCount.current++;
                    // if (notOKCount === num_detections)
                    backgroundcolor.current = "rgba(200,0,0,0.6)";
                } else {
                    backgroundcolor.current = "rgba(0, 0, 0, 0.7)"
                    notOKCount.current = 0
                    critCount.current = 0
                    checkCount.current = 0
                }
                
            }
        }
    })

    return (
        <div className="check-button" >
            <button className="check-button__button"
                disabled={!labelIsTrained.current}
                style={{ backgroundColor: backgroundcolor.current }}
            >
                {/* {console.log(`CheckBox ${id} rendered: ${renders.current++}`)} */}
                {/* {icon} */}
                <span className={`partName_${labelIsTrained.current ? "trained " : "untrained"}`}>{id.current}</span>

            </button>
        </div>
    )
}